import React, { Component } from 'react';
import ContactUsForm from './contact-us-form'
import { languages } from '../../../data/langauges'
class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        /*
        as you see this component resposible for the
        contact us part
        */
        const { locale } = this.props
        return (
            <React.Fragment>
                <div className='contact-us-section py-4' id='contact-us'>
                    <div className='row mx-0'>
                        <div className='col-md-10 col-12 mx-auto'>
                            <div className='header-wrapper'>
                                <h4 className='font-large text-black weight-600'> {languages[locale]['contact_us']}</h4>
                            </div>
                            <div className='contact-us-form-wrapper mt-md-5 mt-3'>
                                <ContactUsForm locale={locale} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ContactUs;