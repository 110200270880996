
export const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export const isNumber = (str) => {
    return !/\D/.test(str);
}
export const isEnglish = (str) => {
    return /^([A-Za-z0-9]|[ ])*$/.test(str)
}
export const hasNumber = (myString) => {
    return /\d/.test(myString);
}
export const isBlank = (str) => {
    return (!str || /^\s*$/.test(str));
}
export const isPhoneNumber = (myString) => {
    if (myString.length >= 5 && myString.length <= 20) {
        if (!((myString.charAt(0) === '+' || this.isNumber(myString.charAt(0))) && (this.isNumber(myString.substring(1, myString.length))))) {
            return false
        }
    } else {
        return false
    }
    return true
}
