import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Input, Select, Icon, notification } from 'antd'
import axios from '../../../helperComponents/axios'
import { languages } from '../../../data/langauges'
// {languages[locale]['email']}
const useStyles = makeStyles(theme => ({
    content: {
        alignItems: 'center',
        justifyContent: 'center',
        float: 'left',
        width: '90%',
        marginTop: 10,
        marginBottom: 10
    },
    textField: {
        marginLeft: 25,
        marginRight: 30,
    },
    card: {
        borderRadius: 10,
        boxShadow: "0 3 10 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#FFFFFF",
        borderLeft: "10px solid #65425f",
    },

}));

const submit = (old,new1,new2,locale) =>{
    const link = localStorage['type'] === 'COMPETITOR'? 'competitor':'judge';
    const data = {
        old_password:old,
        password:new1,
        password_confirmation:new2
    }
    axios({
        method: 'put',
        url: `${link}/update-password`,
        data: data
    }
    ).then((res) => {
        notification.success({
            message: languages[locale]['password_changed'],
        });
        console.log("RESPONSE RECEIVED: update pass", res);
        // window.location.reload();
    }).catch((err) => {
        if (err.response && err.response.status == 422 && err.response.data.errors.email) {
            console.log("AXIOS ERROR: update pass", err.response);
        }
        if(err.response){
            let obj = err.response.data.errors;
            for (let key in obj){
                if(obj.hasOwnProperty(key)){
                    notification.error({
                        message: obj[key][0],
                    });
                }
            }
        }       
    })
}

export default function ChangePassword(props) {
    const classes = useStyles();
    const [old, setOld] = useState();
    const [new1, setNew1] = useState();
    const [new2, setNew2] = useState();
    const {locale} = props;
    return (
        <div className={classes.content}>
            <Card className={classes.card}>

                <CardContent>
                    <div className='font-large weight-500 text-purple-900'>{languages[locale]['change_password']}</div>
                    <div className='form-wrapper form-wrapper-card'>
                        <form className='form' >
                            <div className="row">
                                <div className='col-md-6 col-12'>
                                    <div className='form-input-wrapper'>
                                        <div className='input-wrapper'>
                                            <Input.Password style={{ fontFamily: 'initial' }} onChange={(e)=>setOld(e.target.value)} name='old' value={old} className='form-input' placeholder={languages[locale]['current_password']} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-md-6 col-12'>
                                    <div className='form-input-wrapper'>
                                        <div className='input-wrapper'>
                                            <Input.Password style={{ fontFamily: 'initial' }} onChange={(e)=>setNew1(e.target.value)} name='new1' value={new1} className='form-input' placeholder={languages[locale]['new_password']} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12'>
                                    <div className='form-input-wrapper'>
                                        <div className='input-wrapper'>
                                            <Input.Password style={{ fontFamily: 'initial' }} onChange={(e)=>setNew2(e.target.value)} name='new2' value={new2} className='form-input' placeholder={languages[locale]['renew_password']} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-center" >
                                <div className='col-6 mb-2'>
                                    <div className='btn-wrapper'>
                                        <button type="button" className='btn btn-purple-900 font-Lsmall w-100' onClick={()=>submit(old,new1,new2,locale)}>{languages[locale]['change_password']}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </CardContent>
            </Card>

        </div>


    );
}
