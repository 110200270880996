import React, { Component } from 'react';
import axios from '../../../helperComponents/axios'
import { languages } from '../../../data/langauges'
class WinnersWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [
                { path: '/images/landing-winners/2.png' },
                { path: '/images/landing-winners/1.png' },
                { path: '/images/landing-winners/4.png' },
                { path: '/images/landing-winners/3.png' }],
        }
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: '/images',
        }
        ).then((res) => {
            this.setState({ images: res.data.data })
            console.log("RESPONSE RECEIVED: ", res);
        }).catch((err) => {

            console.log("AXIOS ERROR: ", err);
        })
    }
    render() {
        const { locale } = this.props
        return (
            <React.Fragment>
                <div className='winners-section pt-4'>
                    <div className='row mx-0'>
                        <div className='col-md-10 col-12 mx-auto'>
                            <div className='row mx-0'>
                                <div className='col-lg-6 col-12'>
                                    <div className='d-flex align-items-center justify-content-center h-100 mb-ld-0 mb-4'>
                                        <div className='font-Xlarge weight-600 text-purple-900 text-center'>{languages[locale]['enroll_now_and_become_the_winner']}</div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 text-center'>
                                    <div className='winners-imgs-wrapper'>
                                        {this.state.images.map((img, i) =>
                                            <div key={i} className='winner-img-holder'><img src={img.path} alt='winner' /></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default WinnersWrapper;