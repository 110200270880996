import $ from 'jquery'
export const indexInClass = (node, className) => {
    var myClass = document.getElementsByClassName(className)
    var num = 0;
    for (var i = 0; i < myClass.length; i++) {
        if (myClass[i] === node) {
            return num;
        }
        num++;
    }
    return -1;
}
export const nthIndex = (str, pat, n) => {
    var L = str.length, i = -1;
    while (n-- && i++ < L) {
        i = str.indexOf(pat, i);
        if (i < 0) break;
    }
    return i;
}
export const formatPhoneNumber = (phone) => {
    if (phone.charAt(0) === '+') {
        return phone
    } else {
        return `+${phone}`
    }
}
export const Show422ErrorMessage = (errors) => {
    const keys = Object.keys(errors)
    keys.forEach((key) => {
        if (document.getElementsByClassName(`server-error ${key}`)) {
            document.getElementsByClassName(`server-error ${key}`)[0].innerHTML = errors[key][0]
        }
    });
}
export const convertSvgImgToSvg = () => {
    $('img.svg').each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        $.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });
}