import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom'
import axios from '../../helperComponents/axios'
import { notification, Progress ,message} from 'antd'
import { languages } from '../../data/langauges'
// {languages[locale]['email']}
const useStyles = makeStyles(theme => ({

    drawer: {
        width: 290,
        flexShrink: 1,
        [theme.breakpoints.only('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.only('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.only('md')]: {
            width: 240,
        },
    },
    drawerPaper: {
        width: 290,
        position: 'unset',

        [theme.breakpoints.only('xs')]: {
            width: 1,
        },
        [theme.breakpoints.only('sm')]: {
            width: 150,
        },
        [theme.breakpoints.only('md')]: {
            width: 240,
        },
    },
    profileImage: {
        marginLeft: 'auto !important',
        marginRight: 'auto !important',
        maxWidth: '214px',
        maxHeight: '214px',
        verticalAlign: 'middle',
        borderStyle: 'none',
        display: 'block',
        borderRadius: '50%',
        overflow: 'hidden',
        marginTop: '5px'
    },
    userName: {
        textAlign: 'center',
        color: '#65425f',
    },
    competitionsNumber: {
        textAlign: 'center',
        color: '#65425f',
        fontSize: '12px'
    }


}));

let uploading = false;
const uploadImage = (event, locale,setUploading) => {
    let image = event.target.files[0]
    let type = image.type.indexOf('image') != -1;
    if (!type) {
        message.error(languages[locale]['image_only']);
        return;
    }
    const isLt25M = image.size / 1024 / 1024 < 5;
    if (!isLt25M) {
        message.error(languages[locale]['image_size']);
        return;
    }
    setUploading(true)
    const formFile = new FormData()
    formFile.append('file', image)
    axios({
        method: 'post',
        url: `/uploud?type=image`,
        data: formFile
    }
    ).then((res) => {
        console.log("resres", res)
        image = res.data.path;
        // update profile ..........................
        const id = localStorage['user_id']
        const link = localStorage['type'] === 'COMPETITOR' ? 'competitor' : 'judge';
        let data2 = { 'image': image }
        axios({
            method: 'put',
            url: `${link}/update-profile-picture`,
            data: data2
        }
        ).then((res) => {

            console.log("RESPONSE RECEIVED: update info", res);
            localStorage['profile_picture'] = res.data.data.image;
            window.location.reload();
        }).catch((err) => {
            if (err.response && err.response.status == 422 && err.response.data.errors.email) {
                console.log("AXIOS ERROR: update info", err.response);
            }
            if (err.response) {
                let obj = err.response.data.errors;
                for (let key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        notification.error({
                            message: obj[key][0],
                        });
                    }
                }
            }
        })
        // end of update profile
    }).catch((err) => {
        if (err.response && err.response.status == 422 && err.response.data.errors.email) {
            console.log("AXIOS ERROR: ", err.response);
        }
        if (err.response) {
            let obj = err.response.data.errors;
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    notification.error({
                        message: obj[key][0],
                    });
                }
            }
        }
        console.log("AXIOS ERROR2: ", err.response);
    })
}
export default function ProfileAside(props) {
    const { locale } = props
    const classes = useStyles();
    const sideListCompetitor = [{ text: languages[locale]['profile'], link: 'profile' },
    { text: languages[locale]['enrolled_in_competitons'], link: 'current-competitions' },
    { text: languages[locale]['rest_of_competitions'], link: 'history' }];

    const sideListResident = [{ text: languages[locale]['profile'], link: 'profile' },
    { text: languages[locale]['to_eval_competitons'], link: 'current-competitions' },
    { text: languages[locale]['ended_competitions'], link: 'ended' },
    { text: languages[locale]['evaluated_competitons'], link: 'history' }];
    const renderedList = localStorage['type'] === 'COMPETITOR' ? sideListCompetitor : sideListResident;
    const numberOfComp = localStorage['type'] === 'COMPETITOR' ? languages[locale]['num_of_enroll']: languages[locale]['num_of_eval'];
    const [uploading, setUploading] = useState(false);
    return (
        <div className="col-md-3 aside-bar-container">
            <CssBaseline />
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >

                <div style={{ backgroundColor: '#c1b3bf' }}>

                    {
                        uploading === false ?
                            <div className={classes.profileImage} >
                                <div className={classes.profileImage}>
                                    <img width="214" height="214" src={localStorage['profile_picture'] ? localStorage['profile_picture'] : '/images/profile.png'} alt="profile image" />
                                    <label className='w-100'>
                                        <div className="edit-profile-img btn btn-primary">
                                            <img src='/images/camera.png' width="18"></img>
                                            <span>{languages[locale]['edit']}</span>
                                            <input type='file' className='d-none' name="image" onChange={(e) => {
                                                uploadImage(e,locale,setUploading)
                                               
                                            }} />
                                        </div>



                                    </label>
                                </div>

                            </div>
                            :
                            <div style={{ width: '70%', margin: 'auto', marginTop: '30px' }}>
                                <p className="text-purple-900">{languages[locale]['uploading']}</p>
                                <Progress percent={75} showInfo={true} />
                            </div>
                    }
                    <p className={classes.userName}>
                        <b> {props.data.first_name} {props.data.second_name} {props.data.third_name} {props.data.fourth_name}</b>

                    </p>
                    <p className={classes.competitionsNumber}>{numberOfComp} ({props.data.competitions_count})</p>
                </div>

                <List>
                    {renderedList.map((ele, index) => (
                        <Link to={ele.link} key={ele.text}>
                            <ListItem button selected={window.location.href.indexOf(ele.link) !== -1}>
                                <ListItemText primary={ele.text} className={classes.activeListItem} selected={window.location.href.indexOf(ele.link) !== -1} className="text-right" />
                            </ListItem>
                        </Link>
                    ))}
                    <ListItem button key={'تسجيل الخروج'} onClick={() => props.context.logout()}>
                        <ListItemText primary={languages[locale]['logout']} className={classes.activeListItem} className="text-right" />
                    </ListItem>

                </List>
                <Divider />
            </Drawer>
        </div>

    );
}
