import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Input, Select, Icon, notification } from 'antd'
import axios from '../../../../helperComponents/axios'
import { languages } from '../../../../data/langauges'
import { isNumber, isEnglish } from '../../../../helperFunctions/validation-functions'
// {languages[locale]['email']}

const useStyles = makeStyles(theme => ({
    content: {
        alignItems: 'center',
        justifyContent: 'center',
        float: 'left',
        width: '90%',
        marginTop: 10,
        marginBottom: 10
    },
    textField: {
        marginLeft: 25,
        marginRight: 30,
    },
    card: {
        borderRadius: 10,
        boxShadow: "0 3 10 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#FFFFFF",
        borderLeft: "10px solid #65425f",
    },

}));
const submit = (data) =>{
    const id = localStorage['user_id']
    const link = localStorage['type'] === 'COMPETITOR'? 'competitor':'judge';
    if(data.id)
        axios({
            method: 'put',
            url: `${link}/${data.id}/update-bank-account`,
            data: data
        }
        ).then((res) => {

            console.log("RESPONSE RECEIVED: update info", res);
            window.location.reload();
        }).catch((err) => {
            if (err.response && err.response.status == 422 && err.response.data.errors.email) {
                console.log("AXIOS ERROR: update info", err.response);
            }
            if(err.response){
                let obj = err.response.data.errors;
                for (let key in obj){
                    if(obj.hasOwnProperty(key)){
                        notification.error({
                            message: obj[key][0],
                        });
                    }
                }
            }       
        })
    else
        axios({
            method: 'post',
            url: `${link}/create-bank-account`,
            data: data
        }
        ).then((res) => {

            console.log("RESPONSE RECEIVED: update info", res);
            window.location.reload();
        }).catch((err) => {
            if (err.response && err.response.status == 422 && err.response.data.errors.email) {
                console.log("AXIOS ERROR: update info", err.response);
            }
            if(err.response){
                let obj = err.response.data.errors;
                for (let key in obj){
                    if(obj.hasOwnProperty(key)){
                        notification.error({
                            message: obj[key][0],
                        });
                    }
                }
            }       
        })
}
export default function BankInfo(props) {
    const classes = useStyles();
    const [bank_name, setName] = useState(props.data.bankAccounts?props.data.bankAccounts.bank_name:'');
    const [account_number, setNumber] = useState(props.data.bankAccounts?props.data.bankAccounts.account_number:'');
    const [iban_number, setIban] = useState(props.data.bankAccounts?props.data.bankAccounts.iban_number:'');
    const id = props.data.bankAccounts?props.data.bankAccounts.id:false;
    const {locale} = props;
    
    const handleBankAccount = (val)=>{
        const check = isEnglish(val);
        if(check){
            setNumber(val)
        }
    }
    const handleIban = (val)=>{
        const check = isEnglish(val);
        if(check){
            setIban(val);
        }
    }
    return (
        <div className={classes.content}>
            <Card className={classes.card}>
                <CardContent>
                    <div className='font-large weight-500 text-purple-900'>{languages[locale]['bank_info']}</div>
                    <div className='form-wrapper form-wrapper-card'>
                        <form className='form' >
                            <div className="row">
                                <div className='col-md-6 col-12'>
                                    <div className='form-input-wrapper'>
                                        <div className='input-wrapper'>
                                            <Input className='form-input' value={bank_name} name="first_name" placeholder={languages[locale]['bank_name']} onChange={(e)=>{
                                                setName(e.target.value);
                                            }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12'>
                                    <div className='form-input-wrapper'>
                                        <div className='input-wrapper'>
                                            <Input className='form-input' value={account_number} name="first_name" placeholder={languages[locale]['account_num']} onChange={(e)=>{
                                                handleBankAccount(e.target.value);
                                            }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12'>
                                    <div className='form-input-wrapper'>
                                        <div className='input-wrapper'>
                                            <Input className='form-input' value={iban_number} name="first_name" placeholder={languages[locale]['fast_code']} onChange={(e)=>{
                                                handleIban(e.target.value);
                                            }} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-center" >
                                <div className='col-6 mb-2'>
                                    <div className='btn-wrapper'>
                                        <button type="button" className='btn btn-purple-900 font-Lsmall w-100' onClick={()=>submit({bank_name,account_number,iban_number,id})}>{languages[locale]['change']}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </CardContent>
            </Card>

        </div>


    );
}
