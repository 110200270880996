import React, { Component } from 'react';
import { Pagination } from 'antd'
class PaginationList extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.changePage = this.changePage.bind(this)
    }
    changePage(page_number) {
        this.props.onChange(page_number)
    }
    render() {
        const { total, current_page } = this.props
        return (
            <React.Fragment>
                <Pagination onChange={this.changePage}
                    current={current_page}
                    total={total * 10}
                />
            </React.Fragment>
        );
    }
}

export default PaginationList;