import React, { Component } from 'react';
import { AppContext } from '../../../context/appStateContext'
import { languages } from '../../../data/langauges'
// {languages[locale]['email']}
class EvaluatedLevel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const {locale} = this.props;
        /*
            this componented display the page of evaluated participation
        */
        return (
            <div className='competetions-white-card-section col-md-10'>
                <div className='slider-container row justify-content-center'>
                    <div className='competitions-slider-wrapper owl-theme row col-md-12 justify-content-center'>
                        <div className='upload-section' style={{minHeight:'initial'}}>
                            <h2 className="text-purple-900 font-Xlarge text-center mt-2" style={{ textAlign: "center", paddingTop: "4rem" }}>{this.props.competitionName}</h2>
                            <h2 className="text-purple-900 font-Xlarge text-center mt-2" style={{ textAlign: "center" }}>{this.props.level} {languages[locale]['from_competition']}</h2>
                        </div>
                        
                    </div>
                    <div style={{direction:'rtl'}} className='competitions-slider-wrapper owl-theme row col-md-12 justify-content-center container'>
                        <p className='col-md-12 mt-5 text-purple-900 font-large' >{languages[locale]['level_details']}:</p>
                        <p className='col-md-10 mt-2 text-purple-400 font-medium'>{this.props.details}</p>
        <p className='col-md-12 mt-5 text-purple-900 font-large' >{languages[locale]['evaluation']}: {this.props.evaluation.evaluated === 'NOT_EVALUATED'?<span>{languages[locale]['not_evaluated']}</span>:<span>100 / {this.props.evaluation.score} </span>}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default props => (<AppContext.Consumer>
    {(context) => {
        return <EvaluatedLevel {...props} context={context} />
    }}
</AppContext.Consumer>)