import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../../context/appStateContext';
import Loader from '../Loader';
import { languages } from '../../data/langauges';
class RegisteredSuccessfly extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const { locale } = this.props
        console.log("dldsf",locale)
        if(!localStorage['login_token']){
            return <Redirect to={`${locale}`}/>
        }
        if(localStorage['status'] === 'APPROVED')
            return <Loader isLoading={true} />
        return (
            <React.Fragment>

                <div className='announcment d-flex flex-column justify-content-center align-items-center'>
                    <div className='text-purple-900 font-Xlarge weight-500 text-center mt-3'>
                        {languages[locale]['registered_successfully']}
                    </div>
                    {localStorage['type'] === "JUDGE"?<div className='text-purple-900 font-large weight-500 text-center mt-2'>
                        {languages[locale]['account_under_review']}
                        
                    </div>:(
                        <div className='text-gold-500 font-medium weight-500 text-center mt-2'>
                           {languages[locale]['registered_successfully']}
                            برجاء تفعيل الحساب عن طريق البريد الإلكتروني
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}
export default props => (<AppContext.Consumer>
    {(context) => {
        return <RegisteredSuccessfly {...props} context={context} />
    }}
</AppContext.Consumer>)