import React, { Component } from 'react';
import { AppContext } from '../../context/appStateContext'
import Competitions1 from './competitions-1'
import WinnersWrapper from './winners'
import Competitions2 from './competitons-2'
import ContactUs from './contact-us'
import axios from '../../helperComponents/axios';
import Loader from '../Loader';

class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = { competitions: [], loader: true }
        this.props.context.setContext('navbar', { type: 'full', color: 'tranparentTop' })
        this.props.context.setContext('footer', { type: 'full' })


    }
    componentDidMount() {
        axios({
            method: 'get',
            url: '/competitions',
        }
        ).then((res) => {
            this.setState({ competitions: res.data.data }, () => this.setState({ loader: false }));

        }).catch((err) => {
        })

    }
    render() {

        const { locale } = this.props
        return this.state.loader ? <Loader isLoading={true} />
            : (
                <React.Fragment>

                    <div className='landing-entry'>
                        <Competitions1 locale={locale} competitions={this.state.competitions} />
                    </div>
                    <div className='landing-body'>
                        <div className='landing-container'>
                            <WinnersWrapper locale={locale} />
                            <Competitions2 locale={locale} competitions={this.state.competitions} />
                            <ContactUs locale={locale} />
                        </div>
                    </div>
                </React.Fragment>
            );
    }
}

export default props => (<AppContext.Consumer>
    {(context) => {
        return <LandingPage {...props} context={context} />
    }}
</AppContext.Consumer>)