import React, { Component, Fragment } from "react";

import PersonalInfo from './editable/personalInfo';
import StaticPersonalInfo from './static/personalInfo';
import BankInfo from './editable/bankInfo';
import StaticBankInfo from './static/bankInfo';
import ChangePassword from './changePassword'
import axios from '../../../helperComponents/axios'
import ProfileAside from '../../../components/asideBar'

class Profile extends Component {
    constructor(props){
        super(props);
        this.state={
            staticBankInfo:true,
            staticPersonalInfo:true
        }
        this.changeCard= this.changeCard.bind(this)
    }
    changeCard(type){
        if(type==="personal")
            this.setState({staticPersonalInfo:false})
        if(type==="bank")
            this.setState({staticBankInfo:false})
    }
    render() {
        /*
        in the profile there is the static page and dymnaimc one(the one which user can edit data)
        that's the diff between static-editable 
        */
        console.log("all pfrole",this.props.data)
        const { locale } = this.props
        return (
            <Fragment>
                <ProfileAside data={this.props.data} context={this.props.context} locale={locale}/>
                <div className="col-md-9 col-sm-12 profile_container" >
                    {this.state.staticPersonalInfo?<StaticPersonalInfo changeCard={this.changeCard} locale={locale} data={this.props.data}/>:<PersonalInfo locale={locale} data={this.props.data}/>}
                    {this.state.staticBankInfo?<StaticBankInfo changeCard={this.changeCard} locale={locale} data={this.props.data}/>:<BankInfo locale={locale} data={this.props.data}/>}
                    <ChangePassword locale={locale} />
                </div>
            </Fragment>
            
        )
    }
}

export default Profile;