import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom'
import { languages } from '../../../../data/langauges'
// {languages[locale]['email']}
const useStyles = makeStyles(theme => ({

    drawer: {
        width: 290,
        flexShrink: 1,
        [theme.breakpoints.only('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.only('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.only('md')]: {
            width: 240,
        },
    },
    drawerPaper: {
        width: 290,
        position: 'unset',

        [theme.breakpoints.only('xs')]: {
            width: 1,
        },
        [theme.breakpoints.only('sm')]: {
            width: 150,
        },
        [theme.breakpoints.only('md')]: {
            width: 240,
        },
    },
    userName:{
        textAlign: 'center',
        color:'#65425f',
    },
    competitionsNumber:{
        textAlign: 'center',
        color:'#65425f',
        fontSize:'12px'
    },


}));

// change to class component
export default function EndedSideBar(props){
        const classes = useStyles();
        const { competitionId,competitionName ,locale,levels} = props;
        const selected = parseInt(props.match.params.level_id);
        console.log("props",selected)
        return (
                <div className="col-md-3 aside-bar-container fit-side-bar" style={{minHeight:'75vh'}}>
                    <CssBaseline />
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <List>
                            {levels.map((level, i) => (
                                <Link to={`/${locale}/user/ended/${competitionId}/${competitionName}/level/${level.id}`} key={level.id}>
                                    <ListItem button  selected={level.id === selected}>
                                    <div className={level.id === selected ?"stage-number-active":"stage-number"}>
                                        {i+1}
                                    </div>
                                    <div>
                                        <ListItemText primary={languages[locale]['level_name']}  className={classes.activeListItem} selected={level.id === selected} className="text-right" />
                                        <ListItemText primary={level.name} className={classes.activeListItem} selected={level.id === selected} className="text-right stage-name" />
                                    </div>
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                        <Divider />
                    </Drawer>
                </div>
        );
}
