import React, { Component } from 'react';
import { validator, validate, resetValidator } from '../../../helperComponents/input-validator'
import { validateEmail } from '../../../helperFunctions/validation-functions'
import axios from '../../../helperComponents/axios'
import { decrypt } from '../../../helperComponents/encrypt'
import { Input, InputNumber, notification } from 'antd'
import { languages } from '../../../data/langauges'
import Loader from '../../Loader';
import { Redirect } from 'react-router-dom';
// {languages[locale]['email']}
class ConfirmEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: undefined
        }
    }
    updateInput = (event, select_value) => {
        const target = event.target;
        console.log("select_value", target)
        let value;
        let name;
        if (target) {
            value = target.type === 'checkbox' ? target.checked : target.value;
            name = target.name;
        } else {
            value = select_value
            name = event
        }
        console.log("sss", name, value)

        this.setState({ [name]: value })
    }
    submit = (event) => {
        event.preventDefault()
        const { locale } = this.props
        if (validate(this)) {
            const data = {
                "email": localStorage['email'],
                "code": this.state.code
            }
            axios({
                method: 'post',
                url: `/activate`,
                data: data
            }
            ).then((res) => {
                localStorage['status'] = 'APPROVED'
                window.location.reload();
                console.log("RESPONSE RECEIVED: ", res);
            }).catch((err) => {
                if (err.response && err.response.status == 422) {
                    console.log("AXIOS ERROR: ", err.response);
                    if (err.response) {
                        let obj = err.response.data.errors;
                        for (let key in obj) {
                            if (obj.hasOwnProperty(key)) {
                                notification.error({
                                    message: obj[key][0],
                                });
                            }
                        }
                    }
                }
                console.log("AXIOS ERROR: ", err);
            })
        }
    }
    resend = () =>{
        axios({
            method: 'post',
            url: `/resend`,
            data:{
                email:localStorage['email']
            }
        }
        ).then((res) => {
            console.log("RESPONSE RECEIVED: ", res);
            notification.success({
                message: res.data.message,
            });
        }).catch((err) => {
            if (err.response && err.response.status == 422) {
                console.log("AXIOS ERROR: ", err.response);
                if (err.response) {
                    let obj = err.response.data.errors;
                    for (let key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            notification.error({
                                message: obj[key][0],
                            });
                        }
                    }
                }
            }
            console.log("AXIOS ERROR: ", err);
        })
    }
    render() {
        const { locale } = this.props
        if(!localStorage['login_token']){
            return <Redirect to={`${locale}`}/>
        }
        if(localStorage['status'] === 'APPROVED')
            return <Loader isLoading={true} />
        return (
            <React.Fragment>
                <div className='login-page white-card shadow-card' style={{ borderRadius: '0px', maxWidth: '400px' }}>
                    <h5 className='text-center font-medium mb-2 text-purple-900'>{languages[locale]['activate_account']}</h5>
                    <div className='font-Lsmall mb-4 text-purple-900' style={{color:'red'}}>{languages[locale]['code_sent_with_10min']}</div>
                    <div className='form-wrapper'>
                        <form className='form' onSubmit={this.submit}>
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                    <div className='form-input-wrapper'>
                                        <span style={{ color: 'red' }}>*</span>
                                        <label className="text-purple-900 font-Lsmall">{languages[locale]['code']}</label>
                                        <div className='input-wrapper'>
                                            <InputNumber maxLength={4} minLength={4} style={{ fontFamily: 'initial' }} className='form-input' placeholder={languages[locale]['code']} name="code" value={this.state.code} onChange={this.updateInput.bind(this, 'code')} />
                                        </div>
                                        <div style={{ color: 'red' }} className='input-error-msg-wrapper font-small'>
                                            {validator.message('code', String(this.state.code), 'required|size:4')}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-2'>
                                    <div className='btn-wrapper'>
                                        <button className='btn btn-purple-900 font-Lsmall w-100'>{languages[locale]['activate']}</button>
                                    </div>
                                </div>
                                
                            </div>
                        </form>
                        <div className='row'>
                            <div className='col-12 mb-2'>
                                <div className='btn-wrapper'>
                                    <button type="button" onClick={this.resend} className='btn btn-purple-900 font-Lsmall w-100'>{languages[locale]['send_again']}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ConfirmEmail;