import React, { Component } from "react";
import axios from "../../../helperComponents/axios";
import { notification, message } from "antd";
import { Progress } from "antd";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import VidLoader from "./Loader";
import { languages } from "../../../data/langauges";
// {languages[locale]['email']}
class BeforeUplaod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: false,
      uploadPage: false,
      redirect: false,
    };
    this.updateInput = this.updateInput.bind(this);
    this.updateHome = this.updateHome.bind(this);
  }
  updateHome() {
    this.setState({ redirect: true });
  }
  checkFile = (file) => {
    const { locale } = this.props;
    if (!file) {
      return false;
    }
    console.log(file.type.indexOf("video"), file.type);
    let type = file.type.indexOf("video") == -1 ? 1 : 2;
    let ext =
      file.type.indexOf("mp4") != -1 ||
      file.type.indexOf("WebM") != -1 ||
      file.type.indexOf("webm") != -1 ||
      file.type.indexOf("Ogg") != -1 ||
      file.type.indexOf("ogg") != -1 ||
      file.type.indexOf("mpeg") != -1 ||
      file.type.indexOf("mp3") != -1;
    if (!ext) {
      message.error(languages[locale]["video_audio_only"]);
      return false;
    }
    const isLt25M = file.size / 1024 / 1024 < 25;
    if (!isLt25M) {
      message.error(languages[locale]["video_audio_size"]);
      return false;
    }
    return type;
  };
  updateInput(event, select_value) {
    let file = event.target.files[0];
    const type = this.checkFile(file);
    if (type === false) return;

    this.setState({ uploadPage: true });

    const formFile = new FormData();
    formFile.append("file", file);
    console.log(this.props);
    axios({
      method: "post",
      url: `/competitor/upload-attachment`,
      data: formFile,
    })
      .then((res) => {
        let url = "";
        let data = {
          type: type, // 1 audio , 2 video
          path: res.data.path,
        };
        if (this.props.id !== -1) {
          data.level_id = this.props.id;
          url = `/competitor/attach-material`;
        } else {
          data.competition_id = this.props.competitionId;
          url = `/competitor/attach-temp-participation`;
        }
        console.log("data....", data);
        axios({
          method: "post",
          url: url,
          data: data,
        })
          .then((res) => {
            this.setState({ uploaded: true });

            console.log("RESPONSE RECEIVED: ", res);
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.status == 422 &&
              err.response.data.errors.email
            ) {
              console.log("AXIOS ERROR: ", err.response);
            }
            if (err.response) {
              let obj = err.response.data.errors;
              for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                  notification.error({
                    message: obj[key][0],
                  });
                }
              }
            }
            this.setState({ uploaded: true });

            console.log("AXIOS ERROR2: ", err.response);
          });
        console.log("RESPONSE RECEIVED: ", res);
      })
      .catch((err) => {
        this.setState({ uploaded: true });
        if (
          err.response &&
          err.response.status == 422 &&
          err.response.data.errors.email
        ) {
          console.log("AXIOS ERROR: ", err.response);
        }
        console.log("errr", err);
        if (err.response) {
          let obj = err.response.data.errors;
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              notification.error({
                message: obj[key][0],
              });
            }
          }
        }

        console.log("AXIOS ERROR2: ", err.response);
      });
  }
  displayUpload = () => {
    const { locale } = this.props;
    return (
      <div className="upload-section">
        <h2
          className="text-purple-900 font-Xlarge text-center mt-2"
          style={{ textAlign: "center", paddingTop: "4rem" }}
        >
          {this.props.competitionName}
        </h2>
        <h2
          className="text-purple-900 font-Xlarge text-center mt-2"
          style={{ textAlign: "center" }}
        >
          {this.props.level} {languages[locale]["from_competition"]}
        </h2>
        <div
          className="upload-container"
          style={{ paddingTop: 0, minHeight: "initial" }}
        >
          <div className="announcment d-flex flex-column justify-content-center align-items-center">
            <div className="text-purple-900 font-Xlarge text-center mt-2">
              <span style={{ fontWeight: "initial" }}>
                {languages[locale]["evaluation"]}:
              </span>
              <span
                className="text-purple-400"
                style={{ fontWeight: "initial" }}
              >
                {" "}
                {languages[locale]["not_particpated_yet"]}
              </span>
            </div>
            <div
              className="container"
              style={{ position: "relative", top: "20px" }}
            >
              <div className="row">
                <div className="col-md-12 mx-auto text-center">
                  <label className="w-75">
                    <input
                      type="file"
                      className="d-none"
                      name="competeRec"
                      onChange={this.updateInput}
                    />
                    <span className="btn btn-purple-900 font-Lsmall weight-500 w-100">
                      {languages[locale]["upload_file_to_eval"]}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  stillUploading = () => {
    const { locale } = this.props;
    return (
      <div className="upload-section" style={{ width: "100%" }}>
        <h2
          className="text-purple-900 font-Xlarge text-center mt-2"
          style={{ textAlign: "center", paddingTop: "4rem" }}
        >
          {this.props.competitionName}
        </h2>
        <h2
          className="text-purple-900 font-Xlarge text-center mt-2"
          style={{ textAlign: "center" }}
        >
          {this.props.level} {languages[locale]["from_competition"]}
        </h2>
        <p
          className="text-purple-900 font-medium text-center mt-5"
          style={{ marginBottom: "7%", marginTop: "10px !important" }}
        >
          {languages[locale]["uploading"]}
        </p>
        <div
          className="upload-container"
          style={{ paddingTop: 0, minHeight: "initial" }}
        >
          <div>
            {/* <Progress percent={75} showInfo={false} />
                        <p className="text-purple-900">...جاري رفع الفايل</p> */}
            <VidLoader isLoading={true} />
          </div>
        </div>
      </div>
    );
  };
  finishedUpload = () => {
    const { locale } = this.props;
    return (
      <div className="upload-section">
        <h2
          className="text-purple-900 font-Xlarge text-center mt-2"
          style={{
            textAlign: "center",
            paddingTop: "4rem",
          }}
        >
         {languages[locale]["competiton"]} {this.props.competitionName}
        </h2>
        <h2
          className="text-purple-900 font-Xlarge text-center mt-2"
          style={{ textAlign: "center" }}
        >
         {languages[locale]["level_without_the"]} {this.props.level} 
        </h2>
        <div
          className="upload-container"
          style={{ paddingTop: 0, minHeight: "initial" }}
        >
          <div className="announcment d-flex flex-column justify-content-center align-items-center">
            <div className="text-purple-900 font-Xlarge text-center mt-2">
              <h2 className="text-purple-900 font-large text-center mt-2">
                {languages[locale]["participated_done"]}
              </h2>
              <p className="text-purple-900 font-small text-center mt-2">
                {languages[locale]["will_be_called"]}
              </p>
            </div>
            <div
              className="container"
              style={{ position: "relative", top: "20px" }}
            >
              <div className="row">
                <div className="col-md-12 mx-auto  text-center">
                  <label className="w-75">
                    <input
                      type="button"
                      className="d-none"
                      onClick={this.updateHome}
                    />
                    <span className="btn btn-purple-900 font-Lsmall weight-500 w-100">
                      {languages[locale]["back_to_home"]}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    /*
            this component has 3 states to display
            1- the upload vidoe section
            2- shwing loader after upload
            3- showing options for user after uploading to back to home
        */
    const { locale } = this.props;
    if (this.state.redirect) return <Redirect to={`/${locale}/user`} />;
    if (!this.state.uploadPage && this.props.enrolled !== 'en') return this.displayUpload();
    else {
      if (!this.state.uploaded && this.props.enrolled !== 'en') return this.stillUploading();
      else {
        return this.finishedUpload();
      }
    }
  }
}

export default BeforeUplaod;
