import React, { Component } from 'react';
import { Input } from 'antd'
import { notification } from 'antd'
import axios from '../../../helperComponents/axios'
import { languages } from '../../../data/langauges'
const { TextArea } = Input

class ContactUsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: undefined,
            email: undefined,
            message: undefined,
        }
        this.submit = this.submit.bind(this)
        this.updateInput = this.updateInput.bind(this)
    }
    updateInput(event) {
        console.log("event.target")
        console.log(event.target)
        const target = event.target;
        console.log("select_value", target)
        let value;
        let name;
        if (target) {
            value = target.type === 'checkbox' ? target.checked : target.value;
            name = target.name;
        }
        console.log("sss", name, value)

        this.setState({ [name]: value })
    }
    submit(event) {
        event.preventDefault()
        const { locale } = this.props

        let data = {
            subject: this.state.subject,
            email: this.state.email,
            message: this.state.message
        }
        console.log("data", data)
        axios({
            method: 'post',
            url: `/contact`,
            data: data
        }
        ).then((res) => {
            this.setState({
                subject: undefined,
                email: undefined,
                message: undefined,
            })
            notification.success({
                message: languages[locale]['sent_successfully'],
            });

            console.log("RESPONSE RECEIVED: ", res);
        }).catch((err) => {
            notification.error({
                message: languages[locale]['email_wrong'],
            });
            if (err.response && err.response.status == 422 && err.response.data.errors.email) {
                console.log("AXIOS ERROR: ", err.response);
            }
            console.log("AXIOS ERROR: ", err);
        })
    }
    render() {
        
        const { locale } = this.props
        return (
            <React.Fragment>
                <div className='row mx-0 mt-4'>
                    <div className='col-md-9 col-12 mx-auto'>
                        <div className='form-wrapper'>
                            <form className='form' onSubmit={this.submit}>
                                <div className='row mx-auto'>
                                    <div className='col-md-6 col-12'>
                                        <div className='form-input-wrapper'>
                                            <div className='input-wrapper'>
                                                <Input placeholder={languages[locale]['subject']} name="subject" value={this.state.subject} onChange={this.updateInput} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className='form-input-wrapper'>
                                            <div className='input-wrapper'>
                                                <Input type='email' placeholder={languages[locale]['email']} name="email" value={this.state.email} onChange={this.updateInput} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='form-input-wrapper'>
                                            <div className='input-wrapper'>
                                                <TextArea style={{ height: '200px' }} rows={4} name="message" value={this.state.message} placeholder={`${languages[locale]['write_your_message']}.... `} onChange={this.updateInput} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='btn-wrapper text-center'>
                                            <button className='btn btn-purple-900 font-Lsmall w-50'>{languages[locale]['send']}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ContactUsForm;