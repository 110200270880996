import React, { Component } from "react";
import { AppContext } from "../../../context/appStateContext";
import RateCompetitor from "./rate-video";
import axios from "../../../helperComponents/axios";
class LevelDetailsR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participations: [],
    };
  }
  render() {
    return (
      <div className="competetions-white-card-section col-md-10">
        <div className="slider-container row justify-content-center">
          <div className="competitions-slider-wrapper owl-theme row col-md-12 justify-content-center">
            <RateCompetitor
              currIndex={this.props.currIndex}
              onLevelChange={this.props.onLevelChange}
              competitionName={this.props.competitionName}
              skipAttachmentControl={this.props.skipAttachmentControl}
              attachmentControl={this.props.attachmentControl}
              locale={this.props.locale}
              level={this.props.level}
              details={this.props.details}
              id={this.props.id}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default (props) => (
  <AppContext.Consumer>
    {(context) => {
      return <LevelDetailsR {...props} context={context} />;
    }}
  </AppContext.Consumer>
);
