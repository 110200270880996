import React, { Component, Fragment } from "react";
import axios from "../../../helperComponents/axios";
import { notification, Slider, Icon, Input } from "antd";
import { languages } from "../../../data/langauges";
// {languages[locale]['email']}
import Loader from "../../Loader";
const { TextArea } = Input;
class LevelEvaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      evaluated: false,
      detailsPage: false,
      redirect: false,
      noData: false,
      totalGrade: 0,
      seconds: 0,
      judgement_duration: "00:00:00",
      tests: [],
      file: {},
      participation_id: 0,
      time: 0,
      competitor_id: "",
      notes: "",
      seeked: false,
      enableSubmit: false,
      vidWillWork: true,
      loader: true,
    };
    this.changeInput = this.changeInput.bind(this);
  }
  updateInput(event) {
    console.log("event.target");
    console.log(event.target);
    const target = event.target;
    console.log("select_value", target);
    let value;
    let name;
    if (target) {
      value = target.type === "checkbox" ? target.checked : target.value;
      name = target.name;
    }
    console.log("sss", name, value);

    this.setState({ [name]: value });
  }
  stopSeeking = (event) => {
    event.preventDefault();
    var myAttachment = event.target;
    if (!this.state.seeked) {
      this.setState({ seeked: true });
      myAttachment.currentTime = this.state.time;
    } else {
      this.setState({ seeked: false });
    }
  };
  onPlaying = (event) => {
    console.log(event);
    var myAttachment = event.target;
    if (!this.state.seeked)
      if (!myAttachment.seeking)
        this.setState({ time: myAttachment.currentTime });
  };
  onEnded = () => {
    this.setState({ time: 0 });
  };
  changeInput(value, parent, child, grade) {
    let ele = this.state.tests[parent].tests[child];
    console.log(grade, ele.points, value, this.state.totalGrade);
    grade = grade !== undefined ? grade : ele.points;
    this.setState({ totalGrade: this.state.totalGrade - grade + value });
    this.state.tests[parent].tests[child].grade = value;
    // let index = this.search(id,this.state.tests);
    // this.state.tests[index].grade = value;
  }
  getTests = () => {
    axios({
      method: "get",
      url: `/judge/${this.props.id}/tests`,
    })
      .then((res) => {
        let total = 0;
        res.data.data.forEach((element) => {
          element.tests.forEach((sub) => {
            total += sub.points;
          });
        });
        this.setState({ tests: res.data.data, totalGrade: total });

        console.log("RESPONSE RECEIVED: ", res);
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status == 422 &&
          err.response.data.errors.email
        ) {
          console.log("AXIOS ERROR: ", err.response);
        }
        if (err.response) {
          let obj = err.response.data.errors;
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              notification.error({
                message: obj[key][0],
              });
            }
          }
        }

        console.log("AXIOS ERROR2: ", err.response);
      });
  };
  componentDidMount() {
    this.setState({
      seeked: this.props.attachmentControl !== "INACTIVE",
      draging: this.props.attachmentControl !== "INACTIVE",
    });
    setInterval(() => {
      let seconds = this.state.seconds + 1;
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let sec = Math.floor(seconds % 60);
      let judgement_duration = "";
      if (hours < 10) judgement_duration += "0" + hours + ":";
      else judgement_duration += hours + ":";
      if (minutes < 10) judgement_duration += "0" + minutes + ":";
      else judgement_duration += minutes + ":";
      if (sec < 10) judgement_duration += "0" + sec;
      else judgement_duration += sec;
      const enableSubmit =
        document.getElementById("fileUploaded") &&
        document.getElementById("fileUploaded").duration <= seconds;
      this.setState({ seconds, judgement_duration, enableSubmit });
    }, 1000);
    axios({
      method: "get",
      url: `/judge/${this.props.id}/get-random-participation-to-evaluate`,
    })
      .then((res) => {
        const participation_id = res.data.data.id;
        const file = res.data.data.attachment;
        const vidWillWork = file.path.indexOf(".wma") !== -1 ? true : false;
        this.setState({
          loader: false,
          file,
          participation_id,
          competitor_id: res.data.data.competitor.id,
          noData: false,
          vidWillWork,
        });

        console.log("RESPONSE RECEIVED: random", res);
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status == 422 &&
          err.response.data.errors.email
        ) {
          console.log("AXIOS ERROR: ", err.response);
        }
        if (err.response) {
          // let obj = err.response.data.errors;
          // for (let key in obj){
          //     if(obj.hasOwnProperty(key)){
          //         notification.error({
          //             message: obj[key][0],
          //         });
          //     }
          //  }
        }
        this.setState({ noData: true, loader: false });

        console.log("AXIOS ERROR2: ", err);
      });
    this.getTests();
  }
  allowControl = () => {
    return this.state.file.type === "VIDEO" ? (
      <video
        preload="none"
        controls
        id="fileUploaded"
        src={this.state.file.path}
        style={{ height: "300px", maxWidth: "100%" }}
      >
        Your browser does not support the video tag.
      </video>
    ) : (
      <audio
        preload="none"
        controls
        id="fileUploaded"
        ref="audio_tag"
        src={this.state.file.path}
        type="audio/ogg"
        style={{ maxWidth: "100%" }}
      >
        Your browser does not support the audio element.
      </audio>
    );
  };
  dontAllowControl = () => {
    if (this.state.file.type === "VIDEO") {
      return (
        <video
          preload="none"
          controls
          src={this.state.file.path}
          style={{ height: "300px", maxWidth: "100%" }}
          onSeeking={this.stopSeeking}
          onTimeUpdate={this.onPlaying}
          onEnded={this.onEnded}
        >
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <audio
          preload="none"
          controls
          ref="audio_tag"
          src={this.state.file.path}
          type="audio/ogg"
          onSeeking={this.stopSeeking}
          onTimeUpdate={this.onPlaying}
          onEnded={this.onEnded}
          style={{ maxWidth: "100%" }}
        >
          Your browser does not support the audio element.
        </audio>
      );
    }
  };
  willVidWork = (locale) => {
    if (this.state.vidWillWork) {
      return (
        <span
          style={{ display: "block" }}
          className="text-purple-900 font-Lsmall weight-500 w-100"
        >
          {languages[locale]["video_not_working"]}
        </span>
      );
    }
  };
  displayParticipation = (locale) => {
    return (
      <div className="col-lg-4 mx-auto  text-center mt-5">
        <p className="text-purple-900 font-large weight-500 w-100">
          {languages[locale]["user_id"]}: #{this.state.participation_id}
        </p>
        {/* check if the user allowed to control the video */}
        {this.props.attachmentControl !== "INACTIVE"
          ? this.allowControl()
          : this.dontAllowControl()}

        {/* check if the video will work on the browser, if not display a message */}
        {this.willVidWork(locale)}
        <span style={{ display: "block" }}>
          <a
            href={this.state.file.path}
            className="text-purple-900 font-Lsmall weight-500 w-100"
            target="_blank"
          >
            <Icon className="font-large" type="eye"></Icon>
          </a>
        </span>
      </div>
    );
  };
  displayLoader = () => {
    return (
      <div className="upload-section container-fluid">
        <Loader isLoading={true} />
      </div>
    );
  };
  noParticipationScreen = (locale) => {
    // this screen show that there are no participations in that level to be eval
    return (
      <h2 className="text-purple-900 font-large text-center mt-5">
        {languages[locale]["all_participations_evaluated"]}
      </h2>
    );
  };
  competitionAndLevelName = (locale) => {
    // this function display the names of the competiton and level
    return (
      <div className="row">
        <div className="col-md-2" style={{ order: locale === "ar" ? 0 : 2 }}>
          <h2
            className="text-purple-900 font-large text-center mt-2"
            style={{ paddingTop: "4rem" }}
          >
            {languages[locale]["evaluation_time"]}
          </h2>
          <div>
            <h2 className="text-purple-900 font-large text-center mt-2">
              {this.state.judgement_duration}
            </h2>
          </div>
        </div>
        <div
          className="col-md-9"
          style={{ marginLeft: locale === "ar" ? 0 : "1.25em" }}
        >
          <h2
            className="text-gold-500 font-Xlarge mt-2 "
            style={{ paddingTop: "4rem" }}
          >
            {this.props.competitionName}
          </h2>
          <h2 className="text-purple-900 font-Xlarge mt-2">
            {this.props.level} {languages[locale]["from_competition"]}
          </h2>
        </div>
      </div>
    );
  };
  currentEvalGrade = (locale) => {
    const textDir = locale === "ar" ? "rtl" : "ltr";
    return (
      <div className="col-md-12 mx-auto text-center">
        <p className="text-purple-900 font-large text-center">
          {languages[locale]["total_score"]}: {this.state.totalGrade} / 100
        </p>
        <div className="col-md-6 mx-auto text-center">
          <div className="form-input-wrapper">
            <div className="input-wrapper">
              <TextArea
                style={{ height: "75px", direction: textDir }}
                rows={4}
                name="notes"
                className="mb-2"
                value={this.state.notes}
                placeholder={`${languages[locale]["write_your_notes"]}.... `}
                onChange={this.updateInput.bind(this)}
              />
            </div>
          </div>
        </div>
        <div className=" mx-auto  text-center">
          <label>
            <input
              type="button"
              className="d-none"
              onClick={() =>
                this.props.rateDone(
                  this.state.seconds,
                  this.state.tests,
                  this.state.participation_id,
                  this.state.notes
                )
              }
            />
            <span className="btn btn-purple-900 font-Lsmall weight-500">
              {languages[locale]["save_eval"]}
            </span>
          </label>
        </div>
        {this.props.skipAttachmentControl === "ACTIVE" && (
          <div className=" mx-auto  text-center">
            <label>
              <input
                type="button"
                className="d-none"
                onClick={() => {
                  this.props.onLevelChange(this.props.currIndex);
                }}
              />
              <span className="btn btn-purple-900 font-Lsmall weight-500">
                {languages[locale]["skip"]}
              </span>
            </label>
          </div>
        )}
      </div>
    );
  };
  displayTestSection = (locale) => {
    return (
      <div
        className="col-lg-8 mx-auto row  text-center mt-5 "
        style={{ maxHeight: "500px", overflow: "auto" }}
      >
        {this.state.tests.map((test, i) => (
          <div
            key={i}
            className="col-md-12 row mt-4 mb-4"
            style={{ direction: "rtl", marginLeft: 0, marginRight: 0 }}
          >
            <h4 className="col-md-12 text-purple-900 text-center mb-4">
              {test.name[locale]}
            </h4>
            <div
              className="col-md-11 row criteria_section justify-content-center"
              style={{
                width: "100%",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {test.tests.map((criteria, idx) => (
                <div
                  className="col-md-6 row"
                  style={{ marginBottom: "20px", marginTop: "5px" }}
                >
                  <div className="col-xl-6 text-purple-900 text-right">
                    <p className="criteria_name" style={{ fontSize: 16 }}>
                      {criteria.criteria.name[locale]}:
                    </p>
                  </div>
                  <div className="col-xl-5">
                    <Slider
                      style={{
                        paddingRight: 0,
                        marginTop: 0,
                        direction: "rtl",
                      }}
                      defaultValue={criteria.points}
                      max={criteria.points}
                      reverse={locale === "ar"}
                      onChange={(val) =>
                        this.changeInput(val, i, idx, criteria.grade)
                      }
                    />
                    <div className="bar-grades text-purple-900">
                      <div
                        className="min-grade"
                        style={{ display: "inline-block" }}
                      >
                        0
                      </div>
                      <div
                        className="max-grade"
                        style={{ display: "inline-block" }}
                      >
                        {criteria.points}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };
  render() {
    const { locale } = this.props;
    if (this.state.loader) return this.displayLoader();
    if (this.state.noData) return this.noParticipationScreen(locale);
    /*
        this is the evaluation vid page
        it showes the video with the tests
    */
    return (
      <div className="upload-section container-fluid">
        <Fragment>
          {this.competitionAndLevelName(locale)}
          <div
            className="upload-container row"
            style={{ paddingTop: 0, minHeight: "initial" }}
          >
            {this.displayParticipation(locale)}
            {this.displayTestSection(locale)}
            {this.currentEvalGrade(locale)}
          </div>
        </Fragment>
      </div>
    );
  }
}

export default LevelEvaluation;
