import React, { Component } from 'react';
import { validator, validate, resetValidator } from '../../../helperComponents/input-validator'
import { AppContext } from '../../../context/appStateContext'
import { encrypt } from '../../../helperComponents/encrypt'
import axios from '../../../helperComponents/axios'
import { Input, notification } from 'antd'
import { languages } from '../../../data/langauges'
// {languages[locale]['email']}
class Forgorpassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: undefined
        }
    }
    componentDidMount() {
        resetValidator(this)
    }
    updateInput = (event, select_value) => {

        const target = event.target;
        console.log("select_value", target)
        let value;
        let name;
        if (target) {
            value = target.type === 'checkbox' ? target.checked : target.value;
            name = target.name;
        } else {
            value = select_value
            name = event
        }

        this.setState({ [name]: value })
    }
    submit = (event) => {
        event.preventDefault()

        const { locale } = this.props
        if (validate(this)) {
            const data = {
                email: this.state.email,
            }
            axios({
                method: 'post',
                url: `/forget`,
                data: data
            }
            ).then((res) => {
                notification.success({
                    message: res.data.message
                })
                // console.log(`/${locale}/auth/reset-password/${encrypt(this.state.email).split("/").join("%2%")}`)
                const email = encrypt(this.state.email).split("/").join("&X&X&X")
                this.props.history.push(`/${locale}/auth/reset-password/${email}`)
                console.log("RESPONSE RECEIVED: ", res);
            }).catch((err) => {
                if (err.response && err.response.status == 422 && err.response.data.errors.email) {
                    console.log("AXIOS ERROR: ", err.response);
                    if (err.response) {
                        let obj = err.response.data.errors;
                        for (let key in obj) {
                            if (obj.hasOwnProperty(key)) {
                                notification.error({
                                    message: obj[key][0],
                                });
                            }
                        }
                    }
                }
                console.log("AXIOS ERROR: ", err);
            })
        }
    }
    render() {
        const { locale } = this.props
        return (
            <React.Fragment>
                <div className='login-page white-card shadow-card' style={{ borderRadius: '0px', maxWidth: '400px' }}>
                    <h5 className='text-center font-medium mb-4 text-purple-900'>{languages[locale]['forget_password']}</h5>
                    <div className='form-wrapper'>
                        <form className='form' onSubmit={this.submit}>
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                    <div className='form-input-wrapper'>
                                        <span style={{ color: 'red' }}>*</span>
                                        <label className="text-purple-900 font-Lsmall">{languages[locale]['email']}</label>
                                        <div className='input-wrapper'>
                                            <Input type='email' style={{ fontFamily: 'initial' }} className='form-input' placeholder={languages[locale]['email']} name="email" value={this.state.email} onChange={this.updateInput} />
                                        </div>
                                        <div style={{ color: 'red' }} className='input-error-msg-wrapper font-small'>
                                            {validator.message('email', this.state.email, 'required|email|max:255')}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-2'>
                                    <div className='btn-wrapper'>
                                        <button className='btn btn-purple-900 font-Lsmall w-100'>{languages[locale]['send']}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Forgorpassword;