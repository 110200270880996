import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { validator, validate, resetValidator } from '../../../helperComponents/input-validator'
import { nthIndex } from '../../../helperFunctions/functions'
import { AppContext } from '../../../context/appStateContext'
import axios from '../../../helperComponents/axios'
import { Input, notification, Spin, message } from 'antd'
import SimpleReactValidator from 'simple-react-validator';
import { languages } from '../../../data/langauges'
// {languages[locale]['email']}
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: undefined,
            password: undefined,
            submited:false,
        }
        this.updateInput = this.updateInput.bind(this)
        this.submit = this.submit.bind(this)
    }
    componentDidMount() {
        resetValidator(this)
    }
    updateInput(event, select_value) {

        const target = event.target;
        console.log("select_value", target)
        let value;
        let name;
        if (target) {
            value = target.type === 'checkbox' ? target.checked : target.value;
            name = target.name;
        } else {
            value = select_value
            name = event
        }
        this.setState({ [name]: value })
    }
    submit(event) {
        const { locale } = this.props
        
        event.preventDefault()
        if (validate(this)) {
            this.setState({submited:true})
            const data = {
                email: this.state.email,
                password: this.state.password
            }
            axios({
                method: 'post',
                url: `/login`,
                data: data
            }
            ).then((res) => {
                this.props.context.login(res.data.token,res.data.data.status);
                localStorage['email']=res.data.data.email;
                this.props.context.updateUserInfo(res.data.data.id, res.data.data.first_name, res.data.data.second_name, res.data.data.image, res.data.data.type, res.data.data.status)
                if(res.data.data.type === 'COMPETITOR' && res.data.data.status !== 'APPROVED'){
                    this.props.history.push(`/${locale}/auth/confirm-email`);
                }
                else if(res.data.data.type !== 'COMPETITOR' && res.data.data.status !== 'APPROVED'){
                    this.props.history.push(`/${locale}/auth/registered-successfuly`);
                    // this.props.history.push(`/${locale}/user/current-competitions`);
                }
                else{
                    window.location.reload();
                }
                // if (this.props.match.params.redirect) {
                //     console.log(`/${this.props.location.pathname.substring(nthIndex(this.props.location.pathname, 'login/', 1) + 6, this.props.location.pathname.length)}${this.props.location.search}`)
                //     // this.props.history.push(`/${this.props.location.pathname.substring(nthIndex(this.props.location.pathname, 'login/', 1) + 6, this.props.location.pathname.length)}${this.props.location.search}`);
                //     window.location.reload();
                // }
                console.log("RESPONSE RECEIVED: ", res);
            }).catch((err) => {
                this.setState({submited:false})
                if (err.response && err.response.status == 422 && err.response.data.errors.email) {
                    // document.getElementsByClassName('server-error email')[0].innerHTML = 'البريد الإلكترونى قد يكون خطأ'
                    // document.getElementsByClassName('server-error password')[0].innerHTML = 'كلمة المرور قد يكون خطأ'
                    console.log("AXIOS ERROR: ", err.response);
                    if (err.response) {
                        let obj = err.response.data.errors;
                        for (let key in obj) {
                            if (obj.hasOwnProperty(key)) {
                                notification.error({
                                    message: obj[key][0],
                                });
                            }
                        }
                    }
                }
                else{
                    message.error(languages[locale]['something_wrong'])
                }
                console.log("AXIOS ERROR: ", err);
            })
        }
    }
    render() {
        const { locale } = this.props
        return (
            <React.Fragment>
                <div className='login-page white-card shadow-card' style={{ borderRadius: '0px' , maxWidth:'400px'}}>
                    <div className='form-wrapper'>
                        <form className='form' onSubmit={this.submit}>
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                    <div className='form-input-wrapper'>
                                        <span style={{ color: 'red' }}>*</span>
                                        <label className="text-purple-900 font-Lsmall">{languages[locale]['email']}</label>
                                        <div className='input-wrapper'>
                                            <Input type='email' style={{ fontFamily: 'initial' }} className='form-input' placeholder={languages[locale]['email']} name="email" value={this.state.email} onChange={this.updateInput} />
                                        </div>
                                        <div style={{ color: 'red' }} className='input-error-msg-wrapper font-small'>
                                            {validator.message('email', this.state.email, 'required|email|max:255')}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-2'>
                                    <div className='form-input-wrapper'>
                                        <span style={{ color: 'red' }}>*</span>
                                        <label className="text-purple-900 font-Lsmall">{languages[locale]['password']}</label>
                                        <div className='input-wrapper'>
                                            <Input.Password style={{ fontFamily: 'initial' }} className='form-input' placeholder={languages[locale]['password']} name="password" value={this.state.password} onChange={this.updateInput} />
                                        </div>
                                        <div style={{ color: 'red' }} className='input-error-msg-wrapper font-small'>
                                            {validator.message('password', this.state.password, 'required|max:255')}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-2'>
                                    <div className='btn-wrapper'>
                                        <button className='btn btn-purple-900 font-Lsmall w-100' disabled={this.state.submited}>{this.state.submited && <Spin></Spin>}{languages[locale]['login']}</button>
                                    </div>
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className='text-center'>
                                        <Link to={`/${locale}/auth/forgot-password`} className='font-Lsmall link-purple-450'>{languages[locale]['forget_password']}</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default props => (<AppContext.Consumer>
    {(context) => {
        return <Login {...props} context={context} />
    }}
</AppContext.Consumer>)