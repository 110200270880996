import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { AppContext } from '../../context/appStateContext'
import CompetitionSideBar from './competitionSideBar'
import axios from '../../helperComponents/axios'
import Loader from '../Loader';
class SingleCompetition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            levels: [],
            competitionName: '',
            loader: true
        }
    }
    componentDidMount() {
        const id = this.props.data.match.params.id;
        const enrolled = this.props.data.match.params.enrolled
        this.setState({ competitionName: this.props.data.match.params.name })
        let type = localStorage['type'] === 'COMPETITOR' ? 'competitor' : 'judge';
        if (localStorage['type'] === 'COMPETITOR' && enrolled !== 'in' && enrolled !== 'en')
            axios({
                method: 'post',
                url: `/competitor/${id}/enroll-in-competition`,
            }
            ).then((res) => {
                console.log("RESPONSE RECEIVED: enroll-in-competition", res);
                let url = type === 'judge' ? `/${type}/levels/${id}/competition` : `/${type}/${id}/levels`;
                axios({
                    method: 'get',
                    url: url,
                }
                ).then((res) => {
                    this.setState({ levels: res.data.data, loader: false }, () => this.setState({ loader: false }))
                    console.log("RESPONSE RECEIVED: get level", res, this.state, url);
                }).catch((err) => {

                    console.log("AXIOS ERROR: : get level", err);
                })
            }).catch((err) => {
                console.log("AXIOS ERROR: enroll-in-competition", err);
                let url = type === 'judge' ? `/${type}/levels/${id}/competition` : `/${type}/${id}/levels`;
                axios({
                    method: 'get',
                    url: url,
                }
                ).then((res) => {
                    this.setState({ levels: res.data.data, loader: false }, () => this.setState({ loader: false }))
                    console.log("RESPONSE RECEIVED: get level", res, this.state, url);
                }).catch((err) => {

                    console.log("AXIOS ERROR: : get level", err);
                })
            });
        else{
            let url = type === 'judge' ? `/${type}/levels/${id}/competition` : `/${type}/${id}/levels`;
                axios({
                    method: 'get',
                    url: url,
                }
                ).then((res) => {
                    this.setState({ levels: res.data.data, loader: false }, () => this.setState({ loader: false }))
                    console.log("RESPONSE RECEIVED: get level", res, this.state, url);
                }).catch((err) => {

                    console.log("AXIOS ERROR: : get level", err);
                })
        }
        
    }

    render() {
        const { locale } = this.props
        const enrolled = this.props.data.match.params.enrolled
        if (!this.state.loader)
            return localStorage['login_token'] ?
                (
                    <div className="container-fluid inside-container">
                        <div className="row justify-content-center" style={{ marginLeft: 0 }}>
                            {
                                this.state.levels.length === 0 ? 
                            <CompetitionSideBar locale={locale} enrolled={enrolled} competitionName={this.state.competitionName} levels={this.state.levels} selected={-1} competitionId={this.props.data.match.params.id}/>
                            :
                            <CompetitionSideBar locale={locale} competitionName={this.state.competitionName} levels={this.state.levels} selected={this.state.levels[0].id} selectedName={this.state.levels[0].name} selecteDetails={this.state.levels[0].description} evaluation={this.state.levels[0].evaluation} attachmentControl={this.state.levels[0].attachment_control} skip_attachment_control={this.state.levels[0].skip_attachment_control}/>
                            }
                        </div>
                    </div>
                ) :
                <Redirect to={`/${locale}/`} />
        else
            return <Loader isLoading={true} />
    }
}

export default props => (<AppContext.Consumer>
    {(context) => {
        return <SingleCompetition {...props} context={context} />
    }}
</AppContext.Consumer>)