import React, { Component } from 'react';
import { validator, validate, resetValidator } from '../../../helperComponents/input-validator'
import { validateEmail } from '../../../helperFunctions/validation-functions'
import axios from '../../../helperComponents/axios'
import { decrypt } from '../../../helperComponents/encrypt'
import { Input, InputNumber, notification } from 'antd'
import { languages } from '../../../data/langauges'
// {languages[locale]['email']}
class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: undefined,
            password: undefined,
            code: undefined
        }
    }
    componentDidMount() {
        const { match, locale } = this.props
        if (match.params.email) {
            const email = decrypt(match.params.email.split("&X&X&X").join("/"))

            // const email = match.params.email
            if (match.params.email && validateEmail(email)) {
                this.setState({ email: email })
                // this.setState({ email: match.params.email })
            } else {
                this.props.history.push(`/${locale}/auth/forgot-password`)
            }
        }
        resetValidator(this)
    }
    updateInput = (event, select_value) => {
        const target = event.target;
        console.log("select_value", target)
        let value;
        let name;
        if (target) {
            value = target.type === 'checkbox' ? target.checked : target.value;
            name = target.name;
        } else {
            value = select_value
            name = event
        }
        console.log("sss", name, value)

        this.setState({ [name]: value })
    }
    submit = (event) => {
        event.preventDefault()
        const { locale } = this.props
        if (validate(this)) {
            const data = {

                "email": this.state.email,
                "password": this.state.password,
                "password_confirmation": this.state.password,
                "code": this.state.code
            }
            axios({
                method: 'post',
                url: `/reset`,
                data: data
            }
            ).then((res) => {
                notification.success({
                    message: res.data.message
                })
                this.props.history.push(`/${locale}/auth/login`)
                console.log("RESPONSE RECEIVED: ", res);
            }).catch((err) => {
                if (err.response && err.response.status == 422) {
                    console.log("AXIOS ERROR: ", err.response);
                    if (err.response) {
                        let obj = err.response.data.errors;
                        for (let key in obj) {
                            if (obj.hasOwnProperty(key)) {
                                notification.error({
                                    message: obj[key][0],
                                });
                            }
                        }
                    }
                }
                console.log("AXIOS ERROR: ", err);
            })
        }
    }
    render() {
        const { locale } = this.props
        return (
            <React.Fragment>
                <div className='login-page white-card shadow-card' style={{ borderRadius: '0px', maxWidth: '400px' }}>
                    <h5 className='text-center font-medium mb-2 text-purple-900'>{languages[locale]['change_password']}</h5>
                    <div className='text-center font-Lsmall mb-4 text-purple-900'>{this.state.email}</div>
                    <div className='form-wrapper'>
                        <form className='form' onSubmit={this.submit}>
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                    <div className='form-input-wrapper'>
                                        <span style={{ color: 'red' }}>*</span>
                                        <label className="text-purple-900 font-Lsmall">{languages[locale]['new_password']}</label>
                                        <div className='input-wrapper'>
                                            <Input.Password style={{ fontFamily: 'initial' }} className='form-input' placeholder={languages[locale]['password']} name="password" value={this.state.password} onChange={this.updateInput} />
                                        </div>
                                        <div style={{ color: 'red' }} className='input-error-msg-wrapper font-small'>
                                            {validator.message('password', this.state.password, 'required|min:8|max:255')}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-2'>
                                    <div className='form-input-wrapper'>
                                        <span style={{ color: 'red' }}>*</span>
                                        <label className="text-purple-900 font-Lsmall">{languages[locale]['code']}</label>
                                        <div className='input-wrapper'>
                                            <InputNumber maxLength={4} minLength={4} style={{ fontFamily: 'initial' }} className='form-input' placeholder={languages[locale]['code']} name="code" value={this.state.code} onChange={this.updateInput.bind(this, 'code')} />
                                        </div>
                                        <div style={{ color: 'red' }} className='input-error-msg-wrapper font-small'>
                                            {validator.message('code', String(this.state.code), 'required|size:4')}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-2'>
                                    <div className='btn-wrapper'>
                                        <button className='btn btn-purple-900 font-Lsmall w-100'>{languages[locale]['change']}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ResetPassword;