import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Input, Select, Icon } from 'antd'
import { languages } from '../../../../data/langauges'
// {languages[locale]['email']}
const { Option } = Select

const useStyles = makeStyles(theme => ({
    content: {
        alignItems: 'center',
        justifyContent: 'center',
        float: 'left',
        width: '90%',
        marginTop:90,
        marginBottom:10
    },
    textField: {
        marginLeft: 25,
        marginRight: 30,
    },
    card: {
        borderRadius: 10,
        boxShadow: "0 3 10 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#FFFFFF",
        borderLeft: "10px solid #65425f",
    },

}));

export default function StaticPersonalInfo(props) {
    const gender = (props.data.gender === "MALE"?'1':'2');
    const classes = useStyles();
    const {locale} = props;
    return (
        <div className={classes.content}>
            <Card className={classes.card}>
                <CardContent>
                    <div className='font-large weight-500 text-purple-900'>
                        <span>{languages[locale]['personal_info']}</span>
                        <Icon type="edit" className='profile_card_icon' onClick={()=>props.changeCard("personal")} />
                    </div>
                    <div className='form-wrapper form-wrapper-card'>
                    <form className='form' >
                            <div className="row">
                                <div className='col-md-12 col-12'>
                                    <div className='form-input-wrapper font-medium text-purple-900'>
                                        <div className='input-wrapper'>
                                            <label>{languages[locale]['name']}:</label>
                                            <span> {props.data.first_name} {props.data.second_name} {props.data.third_name} {props.data.fourth_name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 col-12'>
                                    <div className='form-input-wrapper font-medium text-purple-900'>
                                        <div className='input-wrapper'>
                                            <label>{languages[locale]['country']}:</label>
                                            <span> {props.data.country.name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 col-12'>
                                    <div className='form-input-wrapper font-medium text-purple-900'>
                                        <div className='input-wrapper'>
                                            <label>{languages[locale]['gender']}:</label>
                                            <span> {languages[locale][props.data.gender.toLowerCase()]}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 col-12'>
                                    <div className='form-input-wrapper font-medium text-purple-900'>
                                        <div className='input-wrapper'>
                                            <label>{languages[locale]['phone']}:</label>
                                            <span style={{direction:'ltr'}}> {props.data.phone}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 col-12'>
                                    <div className='form-input-wrapper font-medium text-purple-900'>
                                        <div className='input-wrapper'>
                                            <label>{languages[locale]['email']}:</label>
                                            <span> {props.data.email}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </CardContent>
            </Card>

        </div>


    );
}
