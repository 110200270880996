import React from "react";
import PropTypes from "prop-types";
import Lottie from 'react-lottie';
import SabqLoader from './loading.json';


function VidLoader({ isLoading, children, className, style }) {
    if (!isLoading) {
        document.getElementsByTagName('body')[0].style.overflow = 'auto'
        return children;
    }
    else {
        // document.getElementsByTagName('body')[0].style.overflow = 'hidden'
        return (
            <div className={className}
                // style={{ backgroundColor: 'white', zIndex: 1000, minHeight: 300, height: '100%', display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", left: 0, right: 0, top: 0, bottom: 0, ...style }}
                >
                <div className="loader" style={{height:'300px'}}>
                    <Lottie
                        options={{
                            animationData: SabqLoader,
                            loop: true
                        }}
                        style={{
                            // width: '100vh',
                            // height: '150px',
                            // position: 'absolute',
                            left: '50%',
                            top: '50%',
                            // transform: 'translate(-50%, -50%)'
                        }}
                    />

                </div>

            </div>
        );
    }
}

VidLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    size: PropTypes.number
};

VidLoader.defaultProps = {
    className: "",
    style: {},
    size: 80,
    children: () => null
};

export default VidLoader;