import React, { Component } from 'react';
import { setStorage, getStorage, checkStorage, deleteStorage } from '../helperComponents/localStorage'
import { encrypt } from '../helperComponents/encrypt'
// import axios from '../helperComponents/axios'
export const AppContext = React.createContext();
export class AppContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_logged: checkStorage('is_logged') ? getStorage('is_logged') : encrypt('false'),
            login_token: getStorage('login_token'),
            status:undefined,
            // userInfo: { //logged in user info
            //     id: getStorage('user_id'),
            //     first_name: getStorage('first_name'),
            //     last_name: getStorage('last_name'),
            //     profile_picture: getStorage('profile_picture'),
            // },
            navbar: { //navbar status
                type: 'full',
                color: 'transparentTop',
            },
            footer: { //footer status
                type: 'full'
            },

            window_width: window.innerWidth,
            // windowScrollY: window.scrollY
        }
        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.setContext = this.setContext.bind(this)
        this.updateUserInfo = this.updateUserInfo.bind(this)
        this.updateLoginStorage = this.updateLoginStorage.bind(this)
        this.deleteUserStorage = this.deleteUserStorage.bind(this)
    }
    componentDidMount() {
        window.onresize = () => {
            this.setState({ window_width: window.innerWidth })
        }
        // window.onscroll = () => {
        //     this.setState({ windowScrollY: window.scrollY })
        // }
    }
    static getDerivedStateFromProps(props, state) {
        var hours = 0.5; // Reset when last action is more than half an hour
        var now = new Date().getTime();
        var setupTime = parseInt(localStorage.getItem('setupTime'));
        const deleteUserStorage = () => {
            deleteStorage('is_logged')
            deleteStorage('login_token')
        }
        if (setupTime == null) {
            deleteUserStorage()
            localStorage.setItem('setupTime', now)
            return { is_logged: encrypt('false'), login_token: encrypt('') }


        } else {
            if (now - setupTime > hours * 60 * 60 * 1000) {
                deleteUserStorage()
                deleteStorage('setupTime')
                return { is_logged: encrypt('false'), login_token: encrypt('') }

            } else {
                localStorage.setItem('setupTime', now)
            }

        }
        return {}
    }
    setContext(key, value) {
        if (key in this.state)
            this.setState({ [key]: value })
    }
    updateUserInfo(id, first_name, last_name, profile_picture, type , status) {
        this.setState({
            userInfo: {
                id: id,
                first_name: first_name,
                last_name: last_name,
                profile_picture: profile_picture,
                type:type,
                status:status,
            }
        }, () => {
            setStorage('user_id', id)
            setStorage('first_name', first_name)
            setStorage('last_name', last_name)
            setStorage('profile_picture', profile_picture)
            setStorage('type', type)
            setStorage('status', status)
        })

    }
    updateLoginStorage() {
        setStorage('is_logged', this.state.is_logged)
        setStorage('login_token', this.state.login_token)

    }
    login(token,status) {
        this.setState({ is_logged: encrypt('true'), login_token: encrypt(token), status }, () => {
            // this.updateLoginStorage()
            localStorage['is_logged'] = this.state.is_logged;
            localStorage['login_token'] = token;
        })
    }
    deleteUserStorage() {
        localStorage.clear();
        localStorage['is_logged'] = ''
        localStorage['login_token'] = ''
        this.setState({ is_logged: encrypt('false'), login_token: encrypt('') }, () => {
            window.location.reload();
        })
    }
    logout() {
        console.log("this is logout")
        this.deleteUserStorage();
    }
    
    render() {

        return (
            <AppContext.Provider value={{
                state: this.state,
                setContext: this.setContext,
                login: this.login,
                logout: this.logout,
                updateUserInfo: this.updateUserInfo,
                deleteUserStorage: this.deleteUserStorage
            }}>
                {this.props.children}
            </AppContext.Provider>);
    }
}


