import React, { Component } from 'react';
// import ant desgin css
import 'antd/dist/antd.css';
// import sass
import '../sass/app.sass'
//react router
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
//context
import { AppContextProvider, AppContext } from './context/appStateContext'
//helper components
import { setStorage, getStorage, checkStorage } from './helperComponents/localStorage'
import { decrypt } from './helperComponents/encrypt'
import { resetValidator } from './helperComponents/input-validator'

//components
import Navbar from './components/navbar'
import Footer from './components/footer'
//screens
import LandingPage from './screens/landing'
import Auth from './screens/auth'
import UserMain from './screens/user'
import SingleCompetition from './screens/competition'
import axios from './helperComponents/axios';
import { message } from 'antd';
import { languages } from './data/langauges';


class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  UNSAFE_componentWillMount() {
    const { locale } = this.props.match.params;
    axios.interceptors.response.use(res => {
      if (res.data.message) {
        // notification.success({
        //   message: res.data.message,
        // });
      }
      return res
    }, error => {
      var serverError = false
      if (error.response) {
        if (error.response.status == 401) {
          if(localStorage['status'] === 'REJECTED'){
            message.error(languages[locale]['reject_msg'])
          }
          localStorage['login_token'] = '';
        }
      }
      return Promise.reject(error)
    })
  }
  componentDidMount() {

    this.props.history.listen((location) => {
      resetValidator(this);
    })
  }
  render() {
    const { locale } = this.props.match.params;
    const { context, location } = this.props;
    if (localStorage['login_token']) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage['login_token']}`
    }
    axios.defaults.headers.common['Accept-Language'] = locale;
    console.log("axios.defaults", axios.defaults)
    console.log("state", context.state)
    if (!locale || ["ar", "en"].indexOf(locale.toLowerCase()) === -1) {
      var loc = 'ar';
      if (checkStorage('locale')) {
        loc = getStorage('locale')
      } else {
        setStorage('locale', loc)
      }

      return <Redirect to={`/${loc}${location.pathname}${location.search}`} />
    } else {
      setStorage('locale', locale)
    }
    //body rtl
    if (locale === 'ar') {
      var body = document.body;
      body.classList.add("rtl");
    }
    else{
      var body = document.body;
      body.classList.add("ltr");
    }
    return (
      <React.Fragment>
        <Router>
          <Navbar locale={locale} />
          <main>
            <Switch>
              <Route path={`/${locale}/`} exact render={(props) => <LandingPage {...props} locale={locale} />} />
              <Route path={`/${locale}/auth`} render={(props) => <Auth {...props} locale={locale} />} />
              <Route path={`/${locale}/user`} render={(props) => <UserMain {...props} locale={locale} data={props} />} />
              <Route path={`/${locale}/competition/:id/:name/:enrolled`} render={(props) => <SingleCompetition {...props} locale={locale} data={props} />} />
              <Route path={`/${locale}/competition/:id/:name`} render={(props) => <SingleCompetition {...props} locale={locale} data={props} />} />
              {/* default */}
              <Route render={(props) => <Redirect to={`/${locale}/`} />} />
            </Switch>
          </main>
          <Footer locale={locale} />
        </Router>
      </React.Fragment>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <React.Fragment>
        <AppContextProvider>
          <Router>
            <React.Suspense fallback={() => { }}>
              <Switch>
                <Route path="/:locale?" name="Home" render={
                  (props) => <AppContext.Consumer>
                    {(context) => {
                      return <Main {...props} context={context} />
                    }}
                  </AppContext.Consumer>} />
              </Switch>
            </React.Suspense>
          </Router>
        </AppContextProvider>
      </React.Fragment>
    );
  }
}

export default App;
