import React, { Component } from 'react';
import { Input, Select, notification, Icon, Progress,message } from 'antd'
import { AppContext } from '../../../../context/appStateContext'
import axios from '../../../../helperComponents/axios'
import { validate } from '../../../../helperComponents/input-validator'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ar from 'react-phone-input-2/lang/ar.json'
import { languages } from '../../../../data/langauges'
// {languages[locale]['email']}
// const domainName = 'youin.app'
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}
const en = ''
const fields = [
    'first_name',
    'second_name',
    'third_name',
    'fourth_name',
    'email',
    'password',
    'password_confirmation',
    'phone',
    'type',
];
const { Option } = Select
class RegisterResident extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            cities: [],
            country_id: undefined,
            first_name: "",
            second_name: "",
            third_name: "",
            fourth_name: "",
            email: "",
            password: "",
            password_confirmation: "",
            gender: undefined,
            phone: "",
            image: "",
            cv: "",
            type: 2,
            uploading: false,
            uploaded: false,
            errors: {
                first_name: "",
                second_name: "",
                third_name: "",
                fourth_name: "",
                email: "",
                password: "",
                password_confirmation: "",
                phone: "",
            },
            gender_msg:'',
            country_msg:'',
        }
        this.handleContinue = this.handleContinue.bind(this)
        this.renderRegisterForm = this.renderRegisterForm.bind(this)
        this.updateInput = this.updateInput.bind(this)
        this.updateCity = this.updateCity.bind(this)
        this.updateGender = this.updateGender.bind(this)
        this.handlePrevious = this.handlePrevious.bind(this)

        this.submit = this.submit.bind(this)
    }

    updateInput(event, select_value) {
        const { locale } = this.props

        // event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'first_name':
                errors.first_name =
                    value.length < 1
                        ? languages[locale]['this_field_required']
                        : '';
                break;
            case 'second_name':
                errors.second_name =
                    value.length < 1
                        ? languages[locale]['this_field_required']
                        : '';
                break;
            case 'third_name':
                errors.third_name =
                    value.length < 1
                        ? languages[locale]['this_field_required']
                        : '';
                break;
            case 'fourth_name':
                errors.fourth_name =
                    value.length < 1
                        ? languages[locale]['this_field_required']
                        : '';
                break;
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : languages[locale]['wrong_email']
                break;
            case 'password':
                errors.password =
                    value.length < 8
                        ? languages[locale]['min_8_chars']
                        : '';
                break;
            case 'password_confirmation':
                errors.password_confirmation =
                    value.length < 8
                        ? languages[locale]['min_8_chars']
                        : '';
                break;
            case 'phone':
                errors.phone =
                    value.length < 1
                        ? languages[locale]['this_field_required']
                        : '';
                break;
            default:
                break;
        }

        if (name === 'cv') {
            let cv = event.target.files[0]
            if (cv.type.indexOf('pdf') === -1 && cv.type.indexOf('doc') === -1) {
                notification.error({
                    message: languages[locale]['pdf_or_word'],
                });
                return;
            }
            const isLt3M = cv.size / 1024 / 1024 < 3;
            if (!isLt3M) {
                message.error(languages[locale]['cv_size']);
                return;
            }
            this.setState({ uploading: true })
            const formFile = new FormData()
            formFile.append('file', cv)
            axios({
                method: 'post',
                url: `/uploud?type=document`,
                data: formFile
            }
            ).then((res) => {
                cv = res.data.path;
                this.setState({ cv: cv, uploading: false, uploaded: true })
            }).catch((err) => {
                if (err.response && err.response.status == 422 && err.response.data.errors.email) {
                    // document.getElementsByClassName('server-error email')[0].innerHTML = 'البريد الإلكترونى قد يكون خطأ'
                    // document.getElementsByClassName('server-error password')[0].innerHTML = 'كلمة المرور قد يكون خطأ'
                    console.log("AXIOS ERROR: ", err.response);
                }
                if (err.response) {
                    let obj = err.response.data.errors;
                    for (let key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            notification.error({
                                message: obj[key][0],
                            });
                        }
                    }
                }


                console.log("AXIOS ERROR2: ", err.response);
            })
        }
        else this.setState({ errors, [name]: value });

    }
    changePhone = (value) => {
        const { locale } = this.props
        value = value.replace(/\s/g, '')
        let errors = this.state.errors;
        errors.phone =
                    value.length < 1
                        ? languages[locale]['this_field_required']
                        : '';
        this.setState({ phone: value,errors })
    }
    updateCity(value) {
        this.setState({ country_id: value ,country_msg:'' })
        console.log(this.state, value)
    }
    updateGender(value) {
        this.setState({ gender: value, gender_msg:'' })
    }
    componentDidMount() {
        axios({
            method: 'get',
            url: `/countries`,
        }
        ).then((res) => {
            this.setState({ cities: res.data.data })
            console.log("RESPONSE RECEIVED: countries", res);
        }).catch((err) => {
            console.log("AXIOS ERROR: countries", err);
        })
    }
    renderRegisterForm() {
        const { locale } = this.props
        const { errors } = this.state;
        if (this.state.step === 1) {
            return (
                <React.Fragment>
                    <div className='row '>
                        <div className='col-md-6 col-12'>
                            <div className='form-input-wrapper'>
                                <span style={{ color: 'red' }}>*</span>
                                <label className="text-purple-900 font-Lsmall">{languages[locale]['first_name']}</label>
                                <div className='input-wrapper'>
                                    <Input className='form-input' style={{ fontFamily: 'initial' }} placeholder={languages[locale]['first_name']} name="first_name" value={this.state.first_name} onChange={this.updateInput} />
                                    {errors.first_name.length > 0 &&
                                        <span style={{ color: 'red' }} className='server-error font-small first_name'>{errors.first_name}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='form-input-wrapper'>
                                <span style={{ color: 'red' }}>*</span>
                                <label className="text-purple-900 font-Lsmall">{languages[locale]['second_name']}</label>
                                <div className='input-wrapper'>
                                    <Input className='form-input' style={{ fontFamily: 'initial' }} placeholder={languages[locale]['second_name']} name="second_name" value={this.state.second_name} onChange={this.updateInput} />
                                    {errors.second_name.length > 0 &&
                                        <span style={{ color: 'red' }} className='server-error font-small second_name'>{errors.second_name}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='form-input-wrapper'>
                                <span style={{ color: 'red' }}>*</span>
                                <label className="text-purple-900 font-Lsmall">{languages[locale]['third_name']}</label>
                                <div className='input-wrapper'>
                                    <Input className='form-input' style={{ fontFamily: 'initial' }} placeholder={languages[locale]['third_name']} name="third_name" value={this.state.third_name} onChange={this.updateInput} />
                                    {errors.third_name.length > 0 &&
                                        <span style={{ color: 'red' }} className='server-error font-small third_name'>{errors.third_name}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='form-input-wrapper'>
                                <span style={{ color: 'red' }}>*</span>
                                <label className="text-purple-900 font-Lsmall">{languages[locale]['last_name']}</label>
                                <div className='input-wrapper'>
                                    <Input className='form-input' style={{ fontFamily: 'initial' }} placeholder={languages[locale]['last_name']} name="fourth_name" value={this.state.fourth_name} onChange={this.updateInput} />
                                    {errors.fourth_name.length > 0 &&
                                        <span style={{ color: 'red' }} className='server-error font-small fourth_name'>{errors.fourth_name}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='form-input-wrapper'>
                                <span style={{ color: 'red' }}>*</span>
                                <label className="text-purple-900 font-Lsmall">{languages[locale]['email']}</label>
                                <div className='input-wrapper'>
                                    <Input type='text' className='form email' style={{ fontFamily: 'initial' }} placeholder={languages[locale]['email']} name="email" value={this.state.email} onChange={this.updateInput} />
                                    {errors.email.length > 0 &&
                                        <span style={{ color: 'red' }} className='server-error font-small email'>{errors.email}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='form-input-wrapper'>
                                <span style={{ color: 'red' }}>*</span>
                                <label className="text-purple-900 font-Lsmall">{languages[locale]['phone']}</label>
                                <div className='input-wrapper'>
                                    {/* <Input type='tel' className='form phone' style={{ fontFamily: 'initial', direction: 'ltr' }} placeholder='+966011XXXXXXX' name="phone" value={this.state.phone} onChange={this.updateInput} /> */}
                                    <PhoneInput excludeCountries={['il']} type='tel' localization={locale == 'ar' ? ar : en} className='form-input phone_input px-0' placeholder='+966011XXXXXXX' name="phone" value={this.state.phone} onChange={this.changePhone} />
                                    {errors.phone.length > 0 &&
                                        <span style={{ color: 'red' }} className='server-error font-small phone'>{errors.phone}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='form-input-wrapper'>
                                <span style={{ color: 'red' }}>*</span>
                                <label className="text-purple-900 font-Lsmall">{languages[locale]['password']}</label>
                                <div className='input-wrapper'>
                                    <Input.Password className='form password' style={{ fontFamily: 'initial' }} placeholder={languages[locale]['password']} name="password" value={this.state.password} onChange={this.updateInput} />
                                    {errors.password.length > 0 &&
                                        <span style={{ color: 'red' }} className='server-error font-small password'>{errors.password}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='form-input-wrapper'>
                                <span style={{ color: 'red' }}>*</span>
                                <label className="text-purple-900 font-Lsmall">{languages[locale]['confirm_password']}</label>
                                <div className='input-wrapper'>
                                    <Input.Password className='form' style={{ fontFamily: 'initial' }} placeholder={languages[locale]['confirm_password']} name="password_confirmation" value={this.state.password_confirmation} onChange={this.updateInput} />
                                    {errors.password_confirmation.length > 0 &&
                                        <span style={{ color: 'red' }} className='server-error font-small password_confirmation'>{errors.password_confirmation}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else if (this.state.step == 2) {
            return (
                <React.Fragment>
                    <div className='row'>
                        <div className='col-md-6 col-12'>
                            <div className='form-input-wrapper'>
                                <span style={{ color: 'red' }}>*</span>
                                <label className="text-purple-900 font-Lsmall">{languages[locale]['country']}</label>
                                <div className='input-wrapper'>
                                    <Select showSearch filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                        className='form-input' placeholder={languages[locale]['country']} name="country_id" onChange={this.updateCity}>
                                        {this.state.cities.map((city, i) => <Option key={city.id} value={city.id}>{city.name}</Option>)}
                                    </Select>
                                    {this.state.country_msg.length > 0 && 
                                     <span style={{ color: 'red' }} className='server-error font-small country_msg'>{this.state.country_msg}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='form-input-wrapper'>
                                <span style={{ color: 'red' }}>*</span>
                                <label className="text-purple-900 font-Lsmall">{languages[locale]['gender']}</label>
                                <div className='input-wrapper'>
                                    <Select className='form-input' placeholder={languages[locale]['gender']} name="gender" onChange={this.updateGender}>
                                        <Option value='1'>{languages[locale]['male']}</Option>
                                        <Option value='2'>{languages[locale]['female']}</Option>
                                    </Select>
                                    {this.state.gender_msg.length > 0 && 
                                     <span style={{ color: 'red' }} className='server-error font-small gender_msg'>{this.state.gender_msg}</span>}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-5 col-10 mx-auto'>
                            <span style={{ color: 'red' }} className="font-Lsmall">*{languages[locale]['pdf_or_word']}</span>
                            <label className='w-100'>
                                <input type='file' className='d-none' name="cv" onChange={this.updateInput} />
                                <span className='btn btn-purple-400 font-Lsmall weight-500 w-100'><Icon type="upload" /> {languages[locale]['upload_cv']}</span>
                                {this.state.uploaded && <p className="text-purple-900">{languages[locale]['uploaded']}</p>}
                                <div style={{ display: this.state.uploading === false ? 'none' : '' }}>
                                    <p className="text-purple-900">{languages[locale]['uploading']}</p>
                                    <Progress percent={75} showInfo={true} />
                                </div>
                            </label>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
    handleContinue() {
        let { errors } = this.state;
        const { locale } = this.props
        if (this.state.step < 2) {
            if (validateForm(this.state.errors)) {
                let temp = 1;
                fields.forEach(element => {
                    if ((element === 'password' || element === 'password_confirmation') && this.state[element].length < 8) {
                        errors[element] = languages[locale]['min_8_chars'];
                        temp = 0
                    }
                    else if (element === 'password_confirmation' && this.state[element] !== this.state['password']) {
                        errors[element] = languages[locale]['password_not_identical'];
                        temp = 0
                    }
                    else if (this.state[element].length < 1) {
                        errors[element] = languages[locale]['this_field_required'];
                        temp = 0;
                    }
                });
                this.setState({ errors })
                if (temp)
                    setTimeout(() => {
                        this.setState({ step: this.state.step + 1 })
                    }, 200);
            }

        }
    }
    handlePrevious() {
        this.setState({ step: this.state.step - 1 })
    }
    submit(event) {
        const { locale } = this.props
        event.preventDefault()
        let tempState = {}, flag = false;
        if(!this.state.gender){
            flag= true;
            tempState.gender_msg=languages[locale]['this_field_required'];
        }
        if(!this.state.country_id){
            flag = true;
            tempState.country_msg=languages[locale]['this_field_required'];
        }
        if(flag){
            this.setState(tempState)
            return
        }
        if(!this.state.cv){
            message.error(languages[locale]['cv_not_uploaded']);
            return;
        }
        const data = {
            country_id: this.state.country_id,
            first_name: this.state.first_name,
            second_name: this.state.second_name,
            third_name: this.state.third_name,
            fourth_name: this.state.fourth_name,
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation,
            gender: this.state.gender,
            phone: this.state.phone,
            cv: this.state.cv,
            // image:this.state.image,
            type: this.state.type,
        }
        axios({
            method: 'post',
            url: `/register`,
            data: data
        }
        ).then((res) => {
            this.props.context.login(res.data.token, res.data.data.status);
            this.props.context.updateUserInfo(res.data.data.id, res.data.data.first_name, res.data.data.second_name, res.data.data.image, res.data.data.type, res.data.data.status)
            console.log("resresresres",res)
            
            if(res.data.data.status !== 'APPROVED'){
                this.props.history.push(`/${locale}/auth/registered-successfuly`);
                // this.props.history.push(`/${locale}/user/current-competitions`);
            }
            else{
                this.props.history.push({ pathname: `/${locale}/user/current-competitions`, state: { type: 'resident' } })
            }
            // console.log("RESPONSE RECEIVED: ", res);
        }).catch((err) => {
            if (err.response && err.response.status === 422 && err.response.data.errors.email) {
                // document.getElementsByClassName('server-error email')[0].innerHTML = 'البريد الإلكترونى قد يكون خطأ'
                // document.getElementsByClassName('server-error password')[0].innerHTML = 'كلمة المرور قد يكون خطأ'
                console.log("AXIOS ERROR: ", err.response);
            }
            if (err.response) {
                let obj = err.response.data.errors;
                for (let key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        notification.error({
                            message: obj[key][0],
                        });
                    }
                }
            }


            console.log("AXIOS ERROR2: ", err.response);
        })
    }
    render() {
        const { locale } = this.props
        return (
            <React.Fragment>
                <div className='register-page white-card shadow-card' style={{ borderRadius: '0px', minWidth: '300px' }}>
                    <div className='form-wrapper'>
                        <form className='form' onSubmit={this.submit}>
                            {this.renderRegisterForm()}

                            <div className='btn-wrapper mt-2'>
                                {this.state.step === 2 ? (
                                    <div className='row'>
                                        <div className='col-md-4 col-11 mx-auto' >
                                            <button type={this.state.step === 2 ? 'submit' : 'button'} onClick={this.handleContinue} className='btn btn-purple-900 font-Lsmall weigh-500 w-100 mx-auto'>{languages[locale]['continue']}</button>
                                        </div>
                                        <div className='col-md-4 col-11 mx-auto'>
                                            <button onClick={this.handlePrevious} disabled={this.state.step != 2} type="button" className='btn btn-purple-900 font-Lsmall weigh-500 w-100 mx-auto'>{languages[locale]['back']}</button>
                                        </div>
                                    </div>
                                ) : (
                                        <div className='row'>
                                            <div className='col-md-6 col-11 mx-auto' >
                                                <button type={this.state.step === 2 ? 'submit' : 'button'} onClick={this.handleContinue} className='btn btn-purple-900 font-Lsmall weigh-500 w-100 mx-auto'>{languages[locale]['continue']}</button>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default props => (<AppContext.Consumer>
    {(context) => {
        return <RegisterResident {...props} context={context} />
    }}
</AppContext.Consumer>)