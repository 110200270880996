import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { AppContext } from '../../../context/appStateContext'
import { languages } from '../../../data/langauges'
import { Link } from 'react-router-dom'
import { notification } from 'antd'

class CompetitionsWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.showEnrollAlert = this.showEnrollAlert.bind(this)
    }
    showEnrollAlert = () => {
        const {locale} = this.props;
        notification.error({
            message: languages[locale]['judge_can_not_participate']
        })
    }
    render() {
        /*
        just a component to show the compotitions in home
        */
        const { locale } = this.props
        const { context } = this.props
        return (
            <React.Fragment>
                <div className='row mx-0'>
                    <div className='col-md-12 col-12 mx-auto'>
                        <div className='competitions-slider-wrapper'>
                            {
                                this.props.competitions.length === 0 ?
                                    <h2 className="text-purple-900 font-large text-center mt-5">{languages[locale]['no_competitions']}</h2>
                                    :
                                    <OwlCarousel
                                        className="owl-theme"
                                        loop
                                        margin={context.state.window_width > 991 ? -20 : context.state.window_width > 767 ? 5 : 10}
                                        items={context.state.window_width > 1200 ? 3 : context.state.window_width > 767 ? 2 : 1}
                                        nav={false}
                                        dots={false}
                                        autoplay={true}
                                        center={true}

                                    >
                                        {
                                            this.props.competitions.map((value, key) => (
                                                <React.Fragment key={key}>
                                                    <div className='white-card competiotion-card'>
                                                        <div className='img-wrapper'>
                                                            <div className='img-holder'>
                                                                <img src={value.image ? value.image : '/images/competitions/quran.png'} alt='competition-img' />
                                                            </div>
                                                        </div>
                                                        <div className='copetition-name mt-3'>
                                                            <div className='text-purple-1000 weight-500 font-medium text-center text-dots'>
                                                                {value.name}
                                                            </div>
                                                        </div>
                                                        <div className='dates mt-2'>
                                                            <div className='text-purple-1000 weight-500 font-small text-center font-family-en'>{value.from} - {value.to}</div>
                                                        </div>
                                                        <div className='awards mt-md-5 mt-4'>
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    <div className='award-img-wrapper text-center'>
                                                                        <img className='w-75 mx-auto' src='/images/figuers/award-2.png' />
                                                                    </div>
                                                                    <div className='award-label'>
                                                                        <div className='font-small text-purple-1000 text-center weight-500'>{languages[locale]['third_position']}</div>
                                                                    </div>
                                                                    <div className='award-price'>
                                                                        <div className='text-purple-900 text-center'> <span className='font-family-en  weight-600 font-Lsmall'>{value.third_position}</span> <span className='weight-500 font-small'>{languages[locale]['riyal']}</span></div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-4 mt-n4'>
                                                                    <div className='award-img-wrapper text-center'>
                                                                        <img className='w-75 mx-auto' src='/images/figuers/award-1.png' />
                                                                    </div>
                                                                    <div className='award-label'>
                                                                        <div className='font-small text-purple-1000 text-center weight-500'>{languages[locale]['first_position']}</div>
                                                                    </div>
                                                                    <div className='award-price'>
                                                                        <div className='text-purple-900 weight-600 font-Lsmall text-center'><span className='font-family-en  weight-600 font-Lsmall'>{value.first_position}</span> <span className='weight-500 font-small'>{languages[locale]['riyal']}</span></div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <div className='award-img-wrapper text-center'>
                                                                        <img className='w-75 mx-auto' src='/images/figuers/award-2.png' />
                                                                    </div>
                                                                    <div className='award-label'>
                                                                        <div className='font-small text-purple-1000 text-center weight-500'>{languages[locale]['second_position']}</div>
                                                                    </div>
                                                                    <div className='award-price'>
                                                                        <div className='text-purple-900 weight-600 font-Lsmall text-center'><span className='font-family-en  weight-600 font-Lsmall'>{value.second_position}</span> <span className='weight-500 font-small'>{languages[locale]['riyal']}</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className='competition-description pb-2'>
                                                            <div className='font-small weight-500 text-gold-450 text-center'>{value.description}</div>
                                                        </div>
                                                        <div className='btn-wrapper mb-md-n5 mb-n3 mx-md-n4 mx-n2'>
                                                            {localStorage['login_token'] ?
                                                                localStorage['type'] === 'JUDGE' ?
                                                                    <span onClick={() => this.showEnrollAlert()} className='btn btn-purple-900 w-100 font-Lsmall weight-500' style={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }} >{languages[locale]['enroll_now']}</span>
                                                                    :
                                                                    <Link to={`/${locale}/competition/${value.id}/${value.name}`}>
                                                                        <span className='btn btn-purple-900 w-100 font-Lsmall weight-500' style={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }} >{languages[locale]['enroll_now']}</span>
                                                                    </Link>
                                                                :
                                                                <Link to={`/${locale}/auth`}>
                                                                    <span className='btn btn-purple-900 w-100 font-Lsmall weight-500' style={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }} >{languages[locale]['enroll_now']}</span>
                                                                </Link>
                                                            }

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ))}

                                    </OwlCarousel>
                            }
                        </div>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default props => (<AppContext.Consumer>
    {(context) => {
        return <CompetitionsWrapper {...props} context={context} />
    }}
</AppContext.Consumer>)