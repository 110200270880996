import React, { Component } from 'react';
import axios from '../../../helperComponents/axios'
import { notification } from 'antd';
import { Progress } from 'antd';
import { Redirect } from 'react-router-dom'
import LevelEvaluation from './level-eval'
import equal from 'fast-deep-equal'
import { languages } from '../../../data/langauges'
// {languages[locale]['email']}
class RateCompetitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            evaluated:false,
            detailsPage:true,
            redirectHome:false,
            redirectAnother:false,
            timer:'00:00'
        }
        this.updateHome = this.updateHome.bind(this)
        this.rateDone = this.rateDone.bind(this)
        this.updateAnother = this.updateAnother.bind(this)
    }
    componentDidUpdate(prevProps){
        if(!equal(prevProps,this.props)){
            this.setState({
                evaluated:false,
                detailsPage:true,
                redirectHome:false,
                redirectAnother:false,
                timer:'00:00'
            })
        }
    }
    rateDone(seconds,tests_data,id,notes){
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let sec = Math.floor(seconds % 60);
        let judgement_duration = '';
        console.log("minutes",minutes,seconds)
        if(minutes === 0){
            minutes += 1;
        }
        
        if (hours < 10) judgement_duration += '0' + hours + ':';
        else judgement_duration += hours + ':';
        if (minutes < 10) judgement_duration += '0' + minutes;
        else judgement_duration += minutes;

        let tests = [];
        console.log("tests_data",tests_data)
        tests_data.forEach(element => {
            element.tests.forEach(sub => {
                let ele = {};
                ele.value = sub.grade?sub.grade:sub.points;
                if(sub.grade === 0){
                    ele.value = 0;
                }
                ele.test_id = sub.id;
                tests.push(ele);
            });
        });
        console.log("dataa",tests)
        const data={
            judgement_duration,
            level:this.props.id,
            grades:tests,
            notes:notes
        }
        console.log("sent dataaaaaaaaaa",data)
        axios({
            method: 'post',
            url: `/judge/${id}/rate-participation`,
            data:data
        }
        ).then((res) => {
            this.setState({evaluated:false,timer:judgement_duration})

            console.log("RESPONSE RECEIVED: ", res);
        }).catch((err) => {
            if (err.response && err.response.status == 422 && err.response.data.errors.email) {
                console.log("AXIOS ERROR: ", err.response);
            }
            if(err.response){
                let obj = err.response.data.errors;
                for (let key in obj){
                    if(obj.hasOwnProperty(key)){
                        notification.error({
                            message: obj[key][0],
                        });
                    }
                 }
            }
            
           
            console.log("AXIOS ERROR2: ", err.response);
        })
    }
    updateHome(){
        this.setState({redirectHome:true})
    }
    updateAnother(){
        this.props.onLevelChange(this.props.currIndex);
    }
    render() {
        /* 
        this component shows:
            0- page to order an participation to evaluate
            1- video with the tests(to be evaluated) (showed from outer component)
            2- options to go to home (after finishing the eval)
        */
        const {locale} = this.props;
        if(this.state.redirectHome)
            return <Redirect to={`/${locale}/user`} />
        if(this.state.redirectAnother)
            return <Redirect to={`/${locale}/user/current-competitions`} />
        return this.state.detailsPage?
        (
            <div className='upload-section container'>
                <h2 className="text-purple-900 font-Xlarge text-center mt-2" style={{textAlign:"center",paddingTop:"4rem"}}>{this.props.competitionName}</h2>
                <h2 className="text-purple-900 font-Xlarge text-center mt-2" style={{textAlign:"center"}}>{this.props.level} {languages[locale]['from_competition']}</h2>
                <div className='upload-container row' style={{paddingTop:0,minHeight:"initial"}}>
                    <div className="text-purple-900 font-large" style={{width:'80%',marginBottom:30, marginTop:30}}>
                        <span>{languages[locale]['level_details']}</span>
                    </div>
                    <div className="text-purple-400 text-center" style={{width:'75%'}}>
                        {this.props.details}
                    </div>
                    <div className='col-md-6 mx-auto  text-center mt-5'>
                        <label className='w-75'>
                            <input type='button' className='d-none' onClick={()=>this.setState({evaluated:true,detailsPage:false})}/>
                            <span className='btn btn-purple-900 font-Lsmall weight-500 w-100'>{languages[locale]['order_particitpation_to_eval']}
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        ):this.state.evaluated?
        (
            <LevelEvaluation 
                currIndex={this.props.currIndex} 
                competitionName={this.props.competitionName} 
                skipAttachmentControl={this.props.skipAttachmentControl} 
                attachmentControl={this.props.attachmentControl} 
                level={this.props.level} locale={locale} 
                id={this.props.id} 
                rateDone={this.rateDone}
                onLevelChange={this.props.onLevelChange}
            />
        ):(
            <div className='upload-section'>
                <h2 className="text-purple-900 font-Xlarge text-center mt-2" style={{textAlign:"center",paddingTop:"4rem"}}>{this.props.competitionName}</h2>
                <h2 className="text-purple-900 font-Xlarge text-center mt-2" style={{textAlign:"center"}}>{this.props.level} {languages[locale]['from_competition']}</h2>
                <div className='upload-container' style={{paddingTop:0,minHeight:"initial"}}>
                <div className='announcment d-flex flex-column justify-content-center align-items-center'>
                    <div className='text-purple-900 font-Xlarge text-center mt-2' >
                        <h2 className='text-purple-900 font-Xlarge text-center mt-2'>{languages[locale]['evaluated_successfully']}</h2>
                        <p className='text-purple-900 font-large text-center mt-2'>{languages[locale]['evaluation_time']}</p>
                        <p className='text-purple-900 font-large text-center mb-0'>{this.state.timer}</p>
                    </div>
                    <div className="container" style={{position:"relative",top:"20px"}}>
                        <div className='row'>
                            
                            <div className='col-md-5 mx-auto  text-center'>
                                <label className='w-75'>
                                    <input type='button' className='d-none' onClick={this.updateAnother}/>
                                    <span className='btn btn-purple-900 font-Lsmall weight-500 w-100'>{languages[locale]['evaluation_another']}</span>
                                </label>
                            </div>
                            <div className='col-md-5 mx-auto  text-center'>
                                <label className='w-75'>
                                    <input type='button' className='d-none' onClick={this.updateHome}/>
                                    <span className='btn btn-purple-900 font-Lsmall weight-500 w-100'> {languages[locale]['home']}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RateCompetitor;