import SimpleCrypto from "simple-crypto-js";

export const encrypt = (value) => {

    const simpleCrypto1 = new SimpleCrypto("zfdagred");
    return simpleCrypto1.encrypt(value);
}

export const decrypt = (value) => {
    const simpleCrypto1 = new SimpleCrypto("zfdagred");
    return simpleCrypto1.decrypt(value);
}
