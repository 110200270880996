import React, {Component, Fragment} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import EndedSideBar from './endedSideBar';
import Participations from './participations'
import axios from '../../../../helperComponents/axios'
import Loader from '../../../Loader';
class EndedDetails extends Component{
    constructor(props){
        super(props);
        this.state = {
            selected:undefined,
            selectedName:undefined,
            levels:[],
            participations:[],
            loader:true,
            competitionName:undefined,
            competitionId:undefined

        }
    }
    setSelected = () =>{

    }
    setSelectedName = () =>{

    }
    componentDidMount(){
        console.log("totla",this.props)
        const id = this.props.match.params.id
        axios({
            method: 'get',
            url: `/judge/${id}/view-competition`,
        }
        ).then((res) => {
            this.setState({levels:res.data.data.levels,competitionId:res.data.data.id,competitionName:res.data.data.name},()=>{
                if(this.state.levels.length !== 0)
                    this.setState({loader:false,selectedName:this.state.levels[0].name,selected:this.state.levels[0].id})
            })
            console.log("RESPONSE RECEIVED: ", res);
        }).catch((err) => {

            console.log("AXIOS ERROR: ", err);
        })
    }
    render(){
        const {locale} = this.props
        return this.state.loader?<Loader isLoading={true}/>
        :
        localStorage['login_token'] ? 
        (
            <Fragment>
                <Switch>
                        <Route path={`/${locale}/user/ended/:id/:name/level/:level_id`} render={(props) => 
                            <Fragment>
                                <EndedSideBar {...props} selected={this.state.selected} levels={this.state.levels} locale={locale}  competitionId = {this.state.competitionId} competitionName = {this.state.competitionName}/>
                                <div className="col-md-9 col-sm-12 profile_container" >
                                    <Participations locale={locale} {...props}/>
                                </div>
                            </Fragment>
                        } />
                    
                    {/* default */}
                    <Route render={(props) => <Redirect to={`/${locale}/user/ended/${this.state.competitionId}/${this.state.competitionName}/level/${this.state.selected}`} />} />

                </Switch>
            </Fragment>
        ):
        <Redirect to={`/${locale}/`} />
    }
}

export default EndedDetails;