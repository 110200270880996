import React, { Component } from 'react';
import CompetitionsWrapper from './competitions-wrapper'
import { languages } from '../../../data/langauges'
class Competitons2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            competitions: []
        }
    }

    render() {
        const { locale } = this.props
        return (
            <React.Fragment>
                <div className='competetions-white-card-section my-md-5 my-4'>
                    <div className='row mx-0'>
                        <div className='col-md-10 col-12 mx-auto'>
                            <div className='header-wrapper'>
                                <h4 className='text-black font-large weight-600' id="homeCompetitions">{languages[locale]['competitions']}</h4>
                            </div>
                            <div className='slider-container mt-md-5 mt-4'>
                                <CompetitionsWrapper locale={locale} competitions={this.props.competitions} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Competitons2;