import React, { Component } from 'react';
import { AppContext } from '../../context/appStateContext'
import { NavLink } from 'react-router-dom'
import { languages } from '../../data/langauges'
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.renderFooter = this.renderFooter.bind(this)
    }
    renderFooter() {
        const { locale } = this.props
        return (
            <React.Fragment>

                <footer>
                    <div className='footer-container'>
                        <div className='row mx-0'>
                            <div className='col-md-11 col-12 mx-auto'>
                                <div className='row'>
                                    <div className='col-lg-2 col-6 mx-lg-0 mx-auto'>
                                        <div className='footer-header'>
                                            <h5 className='font-medium text-gold-500 weight-500'>{languages[locale]['sabq']}</h5>
                                        </div>
                                        <div className='footer-list mt-md-3'>
                                            <div className='footer-list-item my-2'>
                                                <a href='' className='link-gold-500 font-small weight-500'>{languages[locale]['about_us']}</a>
                                            </div>
                                            <div className='footer-list-item my-2'>
                                                <NavLink to='/' className='link-gold-500 font-small weight-500'>{languages[locale]['home']}</NavLink>
                                            </div>
                                            <div className='footer-list-item my-2'>
                                                <a href='' className='link-gold-500 font-small weight-500'>{languages[locale]['contact_us']}</a>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-1 col-6 mx-lg-0 mx-auto'>
                                        <div className='footer-header'>
                                            <h5 className='font-medium text-gold-500 weight-500'>{languages[locale]['competitions']}</h5>
                                        </div>
                                        <div className='footer-list mt-md-3'>
                                            <div className='footer-list-item my-2'>
                                                <a href='' className='link-gold-500 font-small weight-500'>{languages[locale]['about_us']}</a>
                                            </div>
                                            <div className='footer-list-item my-2'>
                                                <NavLink to='/' className='link-gold-500 font-small weight-500'>{languages[locale]['home']}</NavLink>
                                            </div>
                                            <div className='footer-list-item my-2'>
                                                <a href='' className='link-gold-500 font-small weight-500'>{languages[locale]['contact_us']}</a>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-3  col-7 my-lg-0 my-3  mx-auto   d-flex flex-column justify-content-end pb-lg-4 pb-2'>
                                        <div className='footer-header w-100'>
                                            <h5 className='font-large text-gold-500 weight-500 mb-md-4'>{languages[locale]['download_our_app']}</h5>
                                        </div>
                                        <div className='footer-body'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <a className='d-block'>
                                                        <img className='w-100' src='/images/stores-icons/play-store.png' />
                                                    </a>
                                                </div>
                                                <div className='col-6'>
                                                    <a className='d-block'>
                                                        <img className='w-100' src='/images/stores-icons/apple-store.png' />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-6 mx-lg-0 mx-auto'>
                                        <div className='footer-header'>
                                            <h5 className='font-large text-gold-500 weight-500'>{languages[locale]['need_help']}</h5>
                                        </div>
                                        <div className='footer-list'>
                                            <div className='footer-list-item my-3'>
                                                <div className='list-item-header font-small weight-500 text-gold-500'>{languages[locale]['call_us']}</div>
                                                <a href='tel:002 0103 300 7841' className='link-gold-500 font-Lsmall weight-500 font-family-en'>00201033007841</a>
                                            </div>
                                            <div className='footer-list-item my-3'>
                                                <div className='list-item-header font-small weight-500 text-gold-500'>{languages[locale]['email_us']}</div>
                                                <a href='mailto:Info@sabq.com' className='link-gold-500 font-Lsmall weight-500 font-family-en'>Info@sabq.com</a>
                                            </div>
                                            <div className='footer-list-item my-3'>
                                                <div className='list-item-header font-small weight-500 text-gold-500'>{languages[locale]['follow_us']}</div>
                                                <div className='d-flex justify-content-lg-start justify-content-center mt-2 '>
                                                    <a href='' className='d-block mx-1'><img className='w-75' src='/images/social-media/instagram.png' /></a>
                                                    <a href='' className='d-block mx-1'><img className='w-75' src='/images/social-media/twitter.png' /></a>
                                                    <a href='' className='d-block mx-1'><img className='w-75' src='/images/social-media/facebook.png' /></a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-7 mx-lg-0 mx-auto my-lg-0 my-4 order-lg-last order-first'>
                                        <div className='logo-wrapper h-100 px-lg-0 px-4'>
                                            <div className='d-flex justify-content-center align-items-center h-100'>
                                                <img className='w-75' src='/images/logo/logo.svg' />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
    render() {
        const { context } = this.props
        return (
            <React.Fragment>
                {
                    context.state.footer.type === 'full' ?
                        this.renderFooter()
                        :
                        <React.Fragment></React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default props => (<AppContext.Consumer>
    {(context) => {
        return <Footer {...props} context={context} />
    }}
</AppContext.Consumer>)