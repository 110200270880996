import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { DownOutlined } from "@ant-design/icons";
import { Drawer as AntDrawer, Button } from "antd";

import { AppContext } from "../../context/appStateContext";
import LevelDetails from "./competitor/levelDetails";
import LevelDetailsR from "./resident/levelDetails";
import { languages } from "../../data/langauges";
// {languages[locale]['email']}
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 290,
    flexShrink: 1,
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
    [theme.breakpoints.only("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: 240,
    },
  },
  drawerPaper: {
    width: 290,
    position: "unset",

    [theme.breakpoints.only("xs")]: {
      width: 1,
    },
    [theme.breakpoints.only("sm")]: {
      width: 150,
    },
    [theme.breakpoints.only("md")]: {
      width: 240,
    },
  },
  userName: {
    textAlign: "center",
    color: "#65425f",
  },
  competitionsNumber: {
    textAlign: "center",
    color: "#65425f",
    fontSize: "12px",
  },
}));
function CompetitionSideBar(props) {
  const classes = useStyles();
  const levels = props.levels;
  const [isLoading, setIsLoading] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);
  const [selected, setSelected] = useState(props.selected);
  const [selectedName, setSelectedName] = useState(props.selectedName);
  const [selecteDetails, setSelecteDetails] = useState(props.selecteDetails);
  const [evaluation, setEvaluation] = useState(props.evaluation);
  const [attachmentControl, setAttachmentControl] = useState(
    props.attachmentControl
  );
  const [skipAttachmentControl, setSkipAttachmentControl] = useState(
    props.skip_attachment_control
  );
  const [visible, setVisible] = useState(false);

  const { window_width } = props.context.state;

  const levelChangeHandler = (index) => {
    setIsLoading((state) => !state);
    setCurrIndex(index);
    setAttachmentControl(levels[index].attachment_control);
    setSelected(levels[index].id);
    setSelectedName(levels[index].name);
    setSelecteDetails(levels[index].description);
    setEvaluation(levels[index].evaluation);
    setSkipAttachmentControl(levels[index].skip_attachment_control);
    onClose();
  };

  const levelsList = () => {
    const ListMenu = List;
    const ListMenuItem = ListItem;
    return (
      <ListMenu>
        {levels.map((level, i) => (
          <ListMenuItem
            key={level.id}
            button
            selected={level.id === selected}
            onClick={() => {
              levelChangeHandler(i);
            }}
          >
            <div
              style={{ display: window_width < 991 ? "none" : "block" }}
              className={
                level.id === selected ? "stage-number-active" : "stage-number"
              }
            >
              {i + 1}
            </div>
            <div
              className={
                "list-text-item" +
                (level.id === selected ? " selected-list-text-item" : "")
              }
            >
              <ListItemText
                primary={languages[locale]["level_name"]}
                className={classes.activeListItem}
                selected={level.id === selected}
                className="text-right"
              />
              <ListItemText
                primary={level.name}
                className={classes.activeListItem}
                selected={level.id === selected}
                className="text-right stage-name"
              />
            </div>
          </ListMenuItem>
        ))}
      </ListMenu>
    );
  };
  const mainView = () => {
    if (localStorage["type"] === "JUDGE")
      return (
        <LevelDetailsR // this to display for the judge
          currIndex={currIndex}
          level={selectedName}
          attachmentControl={attachmentControl}
          skipAttachmentControl={skipAttachmentControl}
          locale={locale}
          id={selected}
          competitionName={props.competitionName}
          competitionId={props.competitionId}
          details={selecteDetails}
          onLevelChange={levelChangeHandler}
        />
      );
    else
      return (
        <LevelDetails // this for the competitor part
          level={selectedName}
          locale={locale}
          id={selected}
          evaluation={evaluation}
          competitionName={props.competitionName}
          competitionId={props.competitionId}
          enrolled={props.enrolled}
          details={selecteDetails}
        />
      );
  };
  const { locale } = props;
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <Fragment>
      {window_width < 991 ? (
        <Fragment>
          {/* <Button type="primary" >
            <PlusOutlined /> New account
          </Button> */}
          <Button onClick={showDrawer} style={{ height: 50 }}>
            {languages[locale]["levels"]} <DownOutlined />
          </Button>

          <AntDrawer
            title="المراحل"
            width={720}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            {levelsList()}
          </AntDrawer>
        </Fragment>
      ) : (
        ""
      )}
      <div
        className="col-md-2 aside-bar-container fit-side-bar"
        style={{ minHeight: "75vh" }}
      >
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {levelsList()}
          <Divider />
        </Drawer>
      </div>
      {mainView()}
    </Fragment>
  );
}

export default (props) => (
  <AppContext.Consumer>
    {(context) => {
      return <CompetitionSideBar {...props} context={context} />;
    }}
  </AppContext.Consumer>
);
