import React ,{Component,Fragment} from 'react';
import { languages } from '../../../../data/langauges'
import axios from '../../../../helperComponents/axios'
import Loader from '../../../Loader';
class Participations extends Component{
    constructor(props){
        super(props)
        this.state={
            participations:[],
            competitions:[],
        }
    }
    componentDidMount(){
        // get participations per level
        // {{domain}}/judge/38/participations-by-given-level
        const id = this.props.match.params.level_id
        const competition_name = this.props.match.params.name
        axios({
            method: 'get',
            url: `/judge/${id}/participations-by-given-level`,
        }
        ).then((res) => {
            this.setState({participations:res.data.data,competition_name})
            console.log("RESPONSE RECEIVED: participations-by-given-level", res);
        }).catch((err) => {

            console.log("AXIOS ERROR: ", err);
        })
    }
    render(){
        const { locale } = this.props
        const noCompetitions = this.state.participations.length === 0?true:false;
        return(
            <div className='competetions-white-card-section col-md-9' style={{maxWidth:'100%'}}>
                <div className='slider-container row justify-content-center'>
                    <div className='competitions-slider-wrapper owl-theme row col-md-11 competitons_container' style={{justifyContent:noCompetitions?'center':'initial',marginTop:noCompetitions?'10%':0}}>
                            {
                            noCompetitions ?  
                            <Fragment>
                                <img height="50" src='/images/search_404.png'/>
                                <h2 className="text-purple-900 font-large text-center mt-5">{languages[locale]['no_participations']}</h2>
                            </Fragment>
                            :
                            this.state.participations.map((value, key) => (
                                <React.Fragment key={key}>
                                    <div className="col-md-4" style={{marginTop:'75px'}}>
                                    <div className="card" style={{backgroundColor:'inherit',border:'none'}}>
                                        {
                                            value.attachment.type === 'VIDEO'?
                                            <video preload="none" controls style={{maxHeight:'250px',minHeight:'250px'}}>
                                                <source src={value.attachment.path} />
                                                Your browser does not support the video tag.
                                            </video>
                                            :
                                            <div style={{maxHeight:'250px',minHeight:'250px',position:'relative'}}>
                                                    <audio preload="none" controls style={{position:'absolute',bottom:0,width:'100%'}} >
                                                    <source src={value.attachment.path} />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                        }
                                        <div className="card-body row">
                                            <img src = {value.competitor.image?value.competitor.image: '/images/profile.png'} className="col-md-2 card-img-top"  style={{padding:0,width:'40px',height:'40px', borderRadius:'50%'}} alt="..." />
                                            <div className="col-md-10">
                                                <p className="card-title text-purple-900 weight-500 font-medium">{value.competitor.first_name} {value.competitor.second_name}</p>
                                                <p className="card-text text-purple-900 font-small" style={{marginBottom:'4px'}}>{languages[locale]['competition']}: {this.state.competition_name}</p>
                                                {/* <p className="card-text text-purple-900 font-small">{languages[locale]['evaluation']} : {value.value}/100</p> */}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}

                    </div>

                </div>
            </div>
                
        )
    }
}

export default Participations;