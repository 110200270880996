import SimpleReactValidator from 'simple-react-validator';
import { getStorage } from './localStorage'
let locale = getStorage('locale')


const errorMessages = {
    en: {
        email: 'Enter an email here',
        min: 'Enter at least 8 characteristics',
        required: 'This field is required',
        phone: 'Enter phone number here',
        size: 'Enter at least 4 characteristics'
    },
    ar: {
        email: 'أدخل بريدًا إلكترونيًا هنا',
        min: 'أدخل ما لا يقل عن 8 أحرف',
        required: 'هذا الحقل مطلوب',
        phone: 'أدخل رقم جوال هنا',
        size: 'ادخل ما لا يقل عن 4 ارقام'
    }




}

export const validator = new SimpleReactValidator(
    {
        locale: locale,
        messages: errorMessages[locale],

    },

)
export const resetValidator = (that) => {
    validator.hideMessages()
    validator.purgeFields()
    that.forceUpdate();
}
export const validate = (that) => {
    locale = localStorage['locale'];
    validator.messages = errorMessages[locale]
    if (!validator.allValid()) {
        validator.showMessages();
        that.forceUpdate();
        return false
    } else {
        validator.hideMessages()
        return true
    }
}