import React, { Component } from "react";
import { AppContext } from "../../../context/appStateContext";
import BeforeUplaod from "./upload-video";
import EvaluatedLevel from "./evaluated";
class LevelDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  currentStep = () => {
    const { locale } = this.props;
    if (
      (!this.props.evaluation ||
      this.props.evaluation.file !== "HAS_UPLOADED_FILE")
    ) {
      return (
        <BeforeUplaod
          locale={locale}
          level={this.props.level}
          id={this.props.id}
          competitionName={this.props.competitionName}
          competitionId={this.props.competitionId}
          enrolled={this.props.enrolled}

        />
      );
    } else {
      return (
        <EvaluatedLevel
          level={this.props.level}
          id={this.props.id}
          competitionName={this.props.competitionName}
          evaluation={this.props.evaluation}
          details={this.props.details}
          locale={locale}
        />
      );
    }
  };
  render() {
    /*
            this parent component
            display even the upload page for a video 
            or the details of evaluated participation
        */
    return (
      <div className="competetions-white-card-section col-md-10">
        <div className="slider-container row justify-content-center">
          <div className="competitions-slider-wrapper owl-theme row col-md-12 justify-content-center">
            {this.currentStep()}
          </div>
        </div>
      </div>
    );
  }
}

export default (props) => (
  <AppContext.Consumer>
    {(context) => {
      return <LevelDetails {...props} context={context} />;
    }}
  </AppContext.Consumer>
);
