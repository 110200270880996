import React, { Component, Fragment } from 'react';
import { AppContext } from '../../../context/appStateContext'
import { Link } from 'react-router-dom'
import axios from '../../../helperComponents/axios'
import Loader from '../../Loader';
import ProfileAside from '../../../components/asideBar'
import PaginationList from '../../../components/pagination-list'
import { languages } from '../../../data/langauges'
// {languages[locale]['email']}
class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            competitions:[],
            loader:true,
            pages_number: 0,
            currentPage: 1,

        }
    }
    changePage = (page_number) => {
        this.setState({ currentPage: page_number }, () => {
            this.getJudges(this.state.currentPage)
        })
    }
    componentDidMount(){
        this.getJudges(1)
    }
    getJudges = (page) => {
        axios({
            method: 'get',
            url: `/judge/evaluation-history?page=${page}`,
        }
        ).then((res) => {
            var pages_number = res.data.meta.total / res.data.meta.per_page
            pages_number = Math.ceil(pages_number)
            console.log("RESPONSE RECEIVED:  evaluation-history ", res);
            this.setState({competitions:res.data.data,loader:false,pages_number: pages_number})
        }).catch((err) => {

            console.log("AXIOS ERROR: evaluation-history ", err);
        })
    }
    render() {
        /*
        this is to show the evaluation of participations for judge
        */
        const { locale } = this.props
        const noCompetitions = this.state.competitions.length === 0?true:false;
        return this.state.loader?<Loader isLoading={true}/>:

         (
             <Fragment>
                <ProfileAside data={this.props.data} context={this.props.context} locale={locale}/>

                <div className='competetions-white-card-section col-md-9'>
                    <div className='slider-container row justify-content-center'>
                        <div className='competitions-slider-wrapper owl-theme row col-md-11 competitons_container' style={{justifyContent:noCompetitions?'center':'initial',marginTop:noCompetitions?'10%':0}}>
                                {
                                noCompetitions ?  
                                <Fragment>
                                    <img height="50" src='/images/search_404.png'/>
                                    <h2 className="text-purple-900 font-large text-center mt-5">{languages[locale]['no_competitions']}</h2>
                                </Fragment>
                                :
                                this.state.competitions.map((value, key) => (
                                    <React.Fragment key={key}>
                                        <div className="col-md-4" style={{marginTop:'75px'}}>
                                        <div className="card" style={{backgroundColor:'inherit',border:'none'}}>
                                            {
                                                value.participation.attachment.type === 'VIDEO'?
                                                <video preload="none" controls style={{maxHeight:'250px',minHeight:'250px'}}>
                                                    <source src={value.participation.attachment.path} />
                                                    Your browser does not support the video tag.
                                                </video>
                                                :
                                                <div style={{maxHeight:'250px',minHeight:'250px',position:'relative'}}>
                                                    <audio preload="none" controls style={{position:'absolute',bottom:0,width:'100%'}} >
                                                    <source src={value.participation.attachment.path} />
                                                        Your browser does not support the audio element.
                                                    </audio>
                                                </div>
                                           
                                            }
                                            <div className="card-body row">
                                                <img src = {value.participation.competitor.image?value.participation.competitor.image: '/images/profile.png'} className="col-md-2 card-img-top"  style={{padding:0,width:'40px',height:'40px', borderRadius:'50%'}} alt="..." />
                                                <div className="col-md-10">
                                                    <p className="card-title text-purple-900 weight-500 font-medium">{value.participation.competitor.first_name} {value.participation.competitor.second_name}</p>
                                                    <p className="card-text text-purple-900 font-small" style={{marginBottom:'4px'}}>{languages[locale]['competition']}: {value.participation.level.competition ? value.participation.level.competition.name :'حذفت'}</p>
                                                    <p className="card-text text-purple-900 font-small">{languages[locale]['evaluation']} : {value.total_rate}/100</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}

                        </div>

                    </div>
                    <div className='pagination-list-wrapper d-flex justify-content-center mt-4 mb-5'>
                        <PaginationList total={this.state.pages_number} current_page={this.state.currentPage} onChange={this.changePage} />
                    </div>
                </div>
                
                </Fragment>
            )
    }
}

export default props => (<AppContext.Consumer>
    {(context) => {
        return <History {...props} context={context} />
    }}
</AppContext.Consumer>)